import React, {useEffect} from 'react';
import useScrollTop from '../../../utils/hooks/useScrollTop';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import Product from '@components/product';
import ReactPaginate from 'react-paginate';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Path from '@routes/path';
import {ProductsState} from '@resources/interfaces/api/necklaces';
import {RootState} from '../../../redux/store';
import {connect} from 'react-redux';
import {
  changeNickname,
  getConfig,
  getProducts,
} from '@pages/content/myProducts/actions';
import moment from 'moment';
import MyProductsSkeleton from '@components/myProductsSkeleton';
import ErrorView from '@components/errorView';
import emptyImage from '../../../resources/images/memories.png';
import config from '../../../config';
import {startSession} from '@pages/auth/login/actions';
import {AuthState} from '@resources/interfaces/redux/auth';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5%;
`;
const Title = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s5};
  letter-spacing: 2px;
  text-align: center;
  align-self: center;
  font-weight: 500;
  padding-bottom: 5%;
`;
const EmptyText = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s8};
  letter-spacing: 1px;
  text-align: center;
  align-self: center;
  padding-top: 5%;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    width: 60%;
  }
`;
const ProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
const MyPaginate = styled(ReactPaginate).attrs({
  activeClassName: 'active',
})`
  margin-bottom: 2rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  li a {
    border-radius: 5px;
    padding: 0.1rem 1rem;
    margin: 0 0.5rem;
    border: ${({theme}) => theme.text.grey + ' 1px solid'};
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: ${({theme}) => theme.tertiary + ' 1px solid'};
  }
  li.active a {
    background-color: ${({theme}) => theme.tertiary};
    border-color: transparent;
    color: ${({theme}) => theme.text.light};
    min-width: ${({theme}) => theme.text.s2};
  }
  li.disabled a {
    color: ${({theme}) => theme.text.grey};
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;
const ImageContainer = styled.div`
  width: 70%;
  aspect-ratio: 1.12;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    width: 30%;
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
`;
const Button = styled.button`
  background: ${({theme}) => theme.text.dark};
  border-radius: 10px;
  border: none;
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s7};
  cursor: pointer;
  padding: 2% 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5% auto;
  width: fit-content;
  align-self: center;
  outline: none;
  transition:
    background-color 0.2s,
    transform 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.tertiary};
  }

  &:active {
    background-color: ${({theme}) => theme.tertiary};
    transform: scale(0.98); /* Slightly scale down the button */
  }

  &:focus {
    box-shadow: ${({theme}) => '0 0 0 2px ' + theme.tertiary + '77'};
  }

  @media (min-width: 768px) {
    padding: 1% 5%;
  }
`;
/**
 * MyProducts Component
 * This component displays a paginated list of products fetched from backend.
 *
 * Props: None
 *
 * @returns {JSX.Element} Rendered MyProducts component with a list of products and pagination controls.
 */
const MyProducts = ({
  products,
  auth,
}: {
  products: ProductsState;
  auth: AuthState;
}) => {
  const navigate = useNavigate();
  const {currentUser} = auth || {};
  const [searchParams, setSearchParams] = useSearchParams();
  const {t} = useTranslation();
  const {data, isLoading, isError} = products;
  useScrollTop();

  useEffect(() => {
    loginHandler();
  }, [currentUser]);

  const navigateToMemory = product => {
    navigate(Path.Memories.Root, {state: {product}});
  };
  const loginHandler = async () => {
    if (!currentUser?.token) {
      const token = (searchParams.get('qr_token') || '')?.slice(0, -4);
      setSearchParams('');
      if (!!token) {
        await startSession({token});
        getConfig();
      } else {
        navigate(Path.Auth.login);
      }
    } else if (currentUser?.token) {
      if ((data || []).length === 0) {
        getProducts();
      }
    } else {
      navigate(Path.Auth.login);
    }
  };

  const getDescription = (date, address) => {
    let description = '';
    if (date) {
      description +=
        t('myProducts.purchaseDate') + moment(date).format('DD MMM YYYY');
    }
    if (address) {
      description += '\n' + t('myProducts.deliveryAddress') + address;
    }
    return description;
  };

  const onChangeNameHandler = async (nickname: string, qrcodeId: string) => {
    await changeNickname(qrcodeId, nickname);
  };

  return (
    <Container>
      <Title>{t('myProducts.title')}</Title>
      <ProductsContainer>
        {isError ? (
          <ErrorView onRefresh={getProducts} />
        ) : isLoading ? (
          <MyProductsSkeleton />
        ) : (data || []).length === 0 ? (
          <div>
            <ImageContainer>
              <Image src={emptyImage} />
            </ImageContainer>
            <EmptyText>{t('myProducts.empty')}</EmptyText>
            <Button onClick={() => (window.location.href = config.STORE_URL)}>
              {t('myProducts.openStore')}
            </Button>
          </div>
        ) : (
          (data || []).map(item => (
            <Product
              key={item.id + item?.nickname + item.name}
              onClick={() => navigateToMemory(item)}
              title={item?.nickname || item.name}
              src={item.image}
              description={getDescription(
                item.purchase_date,
                item.full_shipping_address,
              )}
              isBadge={!!+item.is_new}
              isSetTitle={+item.nickname_updated === 0}
              onSaveName={(name: string) =>
                onChangeNameHandler(name, item.qrcode_id)
              }
            />
          ))
        )}
      </ProductsContainer>
      {/* <MyPaginate */}
      {/*   pageCount={5} */}
      {/*   onPageChange={handlePageClick} */}
      {/*   forcePage={currentPage} */}
      {/*   previousLabel={'<'} */}
      {/*   nextLabel={'>'} */}
      {/*   renderOnZeroPageCount={() => null} */}
      {/*   marginPagesDisplayed={0} */}
      {/*   pageRangeDisplayed={2} */}
      {/*   initialPage={1} */}
      {/* /> */}
    </Container>
  );
};
const mapStateToProps = (state: RootState) => ({
  products: state.products,
  auth: state.auth,
});
export default connect(mapStateToProps, null)(MyProducts);
