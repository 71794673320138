import * as React from 'react';
import {SVGProps} from 'react';
const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={57}
    height={57}
    viewBox={'0 0 57 57'}
    fill="none"
    {...props}>
    <path
      stroke="#111"
      d="M.5 28.5c0-15.464 12.536-28 28-28s28 12.536 28 28-12.536 28-28 28-28-12.536-28-28Z"
    />
    <path
      stroke="#111"
      strokeLinecap="round"
      strokeWidth={2}
      d="m23 41 16-12.5L23 16"
    />
  </svg>
);
export default ArrowRightIcon;
