import axios, {InternalAxiosRequestConfig} from 'axios';
import {baseUrl} from './api';
import {getErrorMessage} from '@utils/helper';
import {store} from '../redux/store';
import {endSession} from '@pages/auth/login/actions';

/**
 * create new axios instance.
 * @type {AxiosInstance}
 */
const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
  },
});
axiosInstance.defaults.timeout = 20000;
axiosInstance.defaults.timeoutErrorMessage = 'timeout';
/**
 * create interceptor for request to add token.
 */
axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig<any>) => {
    const {currentUser} = store.getState().auth || null;
    const tempConfig = {...config};
    if (currentUser) {
      tempConfig.headers.Authorization = `Bearer ${currentUser.token}`;
    }
    tempConfig.timeout = 30000;
    tempConfig.timeoutErrorMessage = 'timeout';
    return tempConfig;
  },
  error => {
    Promise.reject(error);
  },
);
/**
 * create axios interceptor to response for refresh token.
 */
axiosInstance.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    if (error?.response?.status === 401) {
      endSession(true);
    }
    return Promise.reject(
      getErrorMessage(error?.response?.data?.error_description),
    );
  },
);

export default axiosInstance;
