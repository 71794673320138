import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '@components/loader';
import {LazyLoadImage} from 'react-lazy-load-image-component';
// Import the stylesheet for default placeholder and animations
import 'react-lazy-load-image-component/src/effects/blur.css';
import {CreateMemoryFieldsProps} from '@resources/interfaces/components/createMemoryFields';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 80%;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  width: 80%;
  aspect-ratio: 1;
  border: ${({theme}) => `1px solid ${theme.border}`};
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
  @media (min-width: 768px) {
    width: 13vw;
    margin: 0 10px;
  }
`;

const Image = styled(LazyLoadImage)`
  width: 100%;
  aspect-ratio: 1;
  border-radius: 10px;
`;
const LoaderContainer = styled.div`
  width: 60%;
  aspect-ratio: 1;
  position: absolute;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    width: 12vw;
    margin: 0 10px;
  }
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  padding-left: 5%;
`;

const Input = styled(DatePicker)`
  width: 43%;
  height: 32px;
  border: ${({theme}) => `1px solid ${theme.border}`};
  border-radius: 10px;
  padding: 5px 10px;
  text-align: center;
`;

const MemoryNameInput = styled.input`
  width: 90%;
  height: 32px;
  border: ${({theme}) => `1px solid ${theme.border}`};
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 18px;
  color: ${({theme}) => theme.text.dark};
  margin-bottom: 20px;
`;
const FieldGroup = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

/**
 * `CreateMemoryFields` Component
 *
 * This component provides a UI for users to create a new memory entry.
 *
 * Note:
 * - Ensure the parent component manages the state for the form values if needed elsewhere in the application.
 * - The `ImagePicker` component is used for selecting the memory's image. Make sure it is correctly implemented and imported.
 *
 * @param {CreateMemoryFieldsProps} props The props for configuring the CreateMemoryFields component.
 * @returns {JSX.Element} The CreateMemoryFields component.
 */
const CreateMemoryFields: React.FC<CreateMemoryFieldsProps> = ({
  image,
  onFormChange,
  style,
}: CreateMemoryFieldsProps): JSX.Element => {
  const [form, updateForm] = useState({
    date: undefined,
    name: '',
  });
  const [isImageLoading, setIsImageLoading] = useState(true);
  useEffect(() => {
    onFormChange && onFormChange(form);
  }, [form]);

  return (
    <Container style={style}>
      <ImageContainer>
        {isImageLoading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        <Image
          alt={'product image'}
          src={image}
          effect="blur"
          onLoad={() => {
            setIsImageLoading(false);
          }}
        />
      </ImageContainer>
      <FieldsContainer>
        <MemoryNameInput
          onChange={event =>
            updateForm(prev => ({...prev, name: event.target.value}))
          }
          placeholder="Memory Name"
        />
        <FieldGroup>
          <Input
            selected={form.date}
            onChange={date => updateForm(prev => ({...prev, date}))}
            dateFormat="dd"
            placeholderText="Day"
          />
          <Input
            selected={form.date}
            onChange={date => updateForm(prev => ({...prev, date}))}
            dateFormat="MM"
            placeholderText="Month"
            showMonthYearPicker
          />
          <Input
            selected={form.date}
            onChange={date => updateForm(prev => ({...prev, date}))}
            dateFormat="yyyy"
            placeholderText="Year"
            showYearPicker
          />
        </FieldGroup>
      </FieldsContainer>
    </Container>
  );
};

export default CreateMemoryFields;
