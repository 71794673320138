import * as React from 'react';
import {SVGProps} from 'react';
const BackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={36}
    height={36}
    viewBox={'0 0 36 36'}
    {...props}
    fill="none"
    style={{
      backgroundColor: 'transparent',
    }}
    stroke={'none'}>
    <path
      stroke={props.fill || '#959595'}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M21.5 11 12 18.5l9.5 7.5M35 18c0 9.389-7.611 17-17 17S1 27.389 1 18 8.611 1 18 1s17 7.611 17 17Z"
    />
  </svg>
);
export default BackIcon;
