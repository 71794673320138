import React from 'react';
import styled, {keyframes} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {RootState} from '../../redux/store';
import LogoIcon from '../../resources/icons/logo';
import {LoadingState} from '@resources/interfaces/connected-component/loading';

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
`;
const Loading = styled.p`
  font-size: ${({theme}) => theme.text.s7};
  color: ${({theme}) => theme.text.light};
  margin-top: ${({theme}) => theme.text.s7};
  letter-spacing: 2px;
  padding-right: 5%;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;
const bounceAnimation = keyframes`
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
`;

const BouncingLoader = styled.div`
  display: flex;
  justify-content: center;

  & > div {
    width: ${({theme}) => theme.text.s11};
    height: ${({theme}) => theme.text.s11};
    margin: 3px 6px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.border};
    opacity: 1;
    animation: ${bounceAnimation} 0.6s infinite alternate;
  }

  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const GlobalLoading = ({loading}: {loading: LoadingState}) => {
  const {t} = useTranslation();
  const {isVisible} = loading || {};
  if (!isVisible) return null;
  return (
    <Container>
      <LogoIcon width={'100%'} height={'6vh'} />
      <Row>
        <Loading>{t('app.loading')}</Loading>
        <BouncingLoader>
          <div></div>
          <div></div>
          <div></div>
        </BouncingLoader>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state?.loading,
  };
};
export default connect(mapStateToProps)(GlobalLoading);
