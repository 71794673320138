import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AuthState, Guest} from '@resources/interfaces/redux/auth';

const initialState: AuthState = {
  currentUser: {},
  guest: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<AuthState>) => {
      state.currentUser = action.payload.currentUser;
    },
    loginGuest: (state, action: PayloadAction<Guest>) => {
      state.guest = action.payload.guest;
    },
    logout: state => {
      state.currentUser = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {login, loginGuest, logout} = authSlice.actions;

export default authSlice.reducer;
