import React, {SVGProps} from 'react';

const GeoLocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    {...props}
    fill={'none'}
    stroke={'none'}>
    <g fill={props.fill || '#000'} clipPath="url(#clip0)">
      <path d="M33.634 7.346a18.962 18.962 0 0 0-13.55-5.497 19.01 19.01 0 0 0-5.178.754A8.165 8.165 0 0 0 8.907 0a8.165 8.165 0 0 0-5.811 2.407A8.224 8.224 0 0 0 .714 7.574a8.224 8.224 0 0 0 1.545 5.475l.373.514a18.926 18.926 0 0 0-1.474 7.36 18.94 18.94 0 0 0 5.677 13.578A18.966 18.966 0 0 0 20.235 40c5.048 0 9.807-1.953 13.4-5.499a18.94 18.94 0 0 0 5.676-13.578c0-5.143-2.016-9.965-5.677-13.577Zm-14.148 31.11c-3.004-.36-5.796-2.867-7.652-6.892 2.335-1.273 4.94-2 7.652-2.114v9.006Zm1.498-9.006c2.711.114 5.316.84 7.652 2.115-1.856 4.024-4.649 6.531-7.652 6.891V29.45Zm0-1.498v-6.28h9.82c-.077 3.026-.621 5.935-1.588 8.503a18.954 18.954 0 0 0-8.232-2.223Zm0-7.778v-6.28a19.047 19.047 0 0 0 8.229-2.228c.969 2.57 1.514 5.48 1.591 8.508h-9.82Zm0-16.782c3 .36 5.794 2.865 7.65 6.886a17.547 17.547 0 0 1-7.65 2.118V3.392Zm8.94 6.109c-.982-2.086-2.212-3.802-3.603-5.077a17.49 17.49 0 0 1 5.695 3.454 17.605 17.605 0 0 1-2.091 1.623ZM17.1 7.574a8.218 8.218 0 0 0-.806-2.951c1.022-.692 2.09-1.104 3.192-1.233v9.006a17.595 17.595 0 0 1-3.26-.445 8.224 8.224 0 0 0 .874-4.377Zm-1.718 5.713c1.337.351 2.713.555 4.104.609v6.278h-9.11l5.006-6.887Zm4.104 8.385v6.28a18.952 18.952 0 0 0-8.231 2.222c-.967-2.568-1.512-5.476-1.589-8.502h9.82Zm-8.942 10.67c.983 2.087 2.212 3.805 3.604 5.08a17.497 17.497 0 0 1-5.696-3.454 17.661 17.661 0 0 1 2.092-1.627Zm19.382 0c.732.485 1.43 1.028 2.092 1.626a17.49 17.49 0 0 1-5.696 3.455c1.391-1.276 2.621-2.994 3.604-5.081Zm.6-1.396c1.088-2.794 1.696-5.973 1.776-9.274h5.495a17.426 17.426 0 0 1-4.711 11.244 19.183 19.183 0 0 0-2.56-1.97Zm7.27-10.772h-5.494c-.08-3.302-.689-6.481-1.777-9.276a19.11 19.11 0 0 0 2.56-1.968 17.42 17.42 0 0 1 4.712 11.244ZM4.156 3.466A6.7 6.7 0 0 1 8.907 1.5a6.7 6.7 0 0 1 4.752 1.965 6.69 6.69 0 0 1 .684 8.703l-5.436 7.48-5.437-7.48a6.69 6.69 0 0 1 .685-8.703Zm3.282 16.708H2.673c.073-1.778.41-3.51 1.006-5.171l3.758 5.171Zm-4.764 1.498h5.495c.08 3.3.689 6.48 1.776 9.273a19.18 19.18 0 0 0-2.56 1.971 17.424 17.424 0 0 1-4.711-11.244Z" />
      <path d="M8.907 13.13a4.918 4.918 0 0 0 4.912-4.911 4.918 4.918 0 0 0-6.878-4.503.749.749 0 0 0 .6 1.372 3.419 3.419 0 0 1 4.781 3.13 3.419 3.419 0 0 1-3.415 3.415A3.419 3.419 0 0 1 5.492 8.22a.749.749 0 1 0-1.497 0 4.918 4.918 0 0 0 4.912 4.912Z" />
      <path d="M4.86 6.328c.254.612 1.152.597 1.39-.017.253-.652-.486-1.268-1.082-.911a.757.757 0 0 0-.307.928c.019.046-.018-.045 0 0Z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill={props.fill || '#000'} d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default GeoLocationIcon;
