/**
 * Types of result response.
 */
export enum Result {
  SUCCESS = 'success',
  ERROR = 'error',
}
/**
 * Types of Qr status.
 */
export enum QrStatus {
  NO_PASSCODE = 'NoPasscode',
  GEO_LOCATION = 'GeoLocation',
  PASSCODE = 'Passcode',
}
