import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ProductsState} from '@resources/interfaces/api/necklaces';

const initialState: ProductsState = {
  data: [],
  isLoading: true,
  isError: false,
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    productsLoading: state => {
      state.isLoading = true;
      state.isError = false;
      state.data = [];
    },
    productsFail: state => {
      state.isLoading = false;
      state.isError = true;
    },
    setProducts: (state, action: PayloadAction<ProductsState>) => {
      state.data = action.payload.data;
      state.isLoading = false;
      state.isError = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setProducts, productsLoading, productsFail} =
  productsSlice.actions;

export default productsSlice.reducer;
