import React, {useRef} from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowRight from '../../resources/icons/arrowRight';
import ArrowLeft from '../../resources/icons/arrowLeft';
import MobileSkeleton from '../../resources/icons/mobile';
import {MemoryPreviewProps} from '@resources/interfaces/components/memoryPreview';

// Styles for the slider's container
const MemoryPreviewContainer = styled.div`
  display: flex;
  position: relative;
  max-width: 100%;
  align-self: center;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 0 0 auto;
  width: 100%;
`;

const ArrowContainer = styled.div`
  width: ${({theme}) => theme.text.s1};
  aspect-ratio: 1;
  &:active {
    transform: scale(0.95);
    opacity: 0.8;
  }
  margin: 10px;
`;
const MobileSkeletonContainer = styled.div`
  width: 80%;
  display: flex;
  align-self: center;
`;
const MobileSkeletonContent = styled.div`
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 68%;
  aspect-ratio: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SliderContainer = styled(Slider)`
  flex: 0 0 auto;
  width: 100%;
  margin: 0 10px;
`;
const ImageContainer = styled.div`
  display: flex;
  width: 92% !important;
  align-self: center;
  align-items: flex-start;
  justify-content: flex-start;
`;
const ImageView = styled.img`
  width: 90%;
  aspect-ratio: 0.6;
  border-radius: 12px;
  align-self: flex-start;
  margin-left: auto;
  object-fit: contain;
  background-color: ${({theme}) => theme.border + '77'};
`;
// to hide default buttons
const DefaultArrow = () => null;

/**
 * `MemoryPreview` Component
 *
 * Displays a slider for previewing a series of images. This component is designed to allow users to
 * browse through memory images in a mobile-like frame. Left and right arrows are provided to navigate
 * through the images.
 *
 * @param {MemoryPreviewProps} props The props for configuring the MemoryPreview component.
 * @returns {JSX.Element} The rendered MemoryPreview component.
 */
const MemoryPreview: React.FC<MemoryPreviewProps> = ({
  images,
  style,
}: MemoryPreviewProps): JSX.Element => {
  const sliderRef = useRef<Slider>(null);
  return (
    <MemoryPreviewContainer style={style}>
      <Row>
        <ArrowContainer onClick={() => sliderRef?.current.slickNext()}>
          <ArrowLeft width={'100%'} />
        </ArrowContainer>
        <MobileSkeletonContainer>
          <MobileSkeleton width={'100%'} height={'100%'}>
            <MobileSkeletonContent>
              <SliderContainer
                ref={sliderRef}
                nextArrow={<DefaultArrow />}
                prevArrow={<DefaultArrow />}>
                {((images || []).length === 1
                  ? [...images, ...images]
                  : images || []
                ).map((img, index) => (
                  <ImageContainer key={index}>
                    <ImageView src={img} alt={`Memory ${index}`} />
                  </ImageContainer>
                ))}
              </SliderContainer>
            </MobileSkeletonContent>
          </MobileSkeleton>
        </MobileSkeletonContainer>
        <ArrowContainer onClick={() => sliderRef?.current.slickNext()}>
          <ArrowRight width={'100%'} />
        </ArrowContainer>
      </Row>
    </MemoryPreviewContainer>
  );
};

export default MemoryPreview;
