import * as React from 'react';
import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={37}
    viewBox={'0 0 36 37'}
    fill="none"
    {...props}>
    <path fill="#D9D9D9" d="M16 0h4v37h-4z" />
    <path fill="#D9D9D9" d="M0 20v-4h36v4z" />
  </svg>
);
export default SvgComponent;
