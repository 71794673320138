import i18n from 'i18next';
import {store} from '../redux/store';

/**
 * This method to get the error message from error description response
 * @param {string|string[]} error
 * @param {string} fallBackMessage
 *
 * @return {string}
 */
export const getErrorMessage = (
  error: string | string[],
  fallBackMessage: string = i18n.t('app.error'),
): string => {
  if (!error) {
    return fallBackMessage;
  }
  if (typeof error === 'string') {
    if (error.includes('undefined')) {
      return fallBackMessage;
    }
    return error;
  }
  if (error.length) {
    return getErrorMessage(error[0]);
  }
  return fallBackMessage;
};
/**
 * this method to get the memory  count that allowed to user to add
 * from auth reducer
 *
 * @return {number} - the memory count
 */
export const getMemoriesCount = (): number => {
  const {currentUser} = store.getState().auth;
  if (!currentUser?.config?.settings) {
    return 0;
  }
  const idx = (currentUser?.config?.settings || []).findIndex(
    item => item.key === 'max_memory_count',
  );
  if (idx > -1) {
    return +currentUser?.config?.settings[idx].value;
  }
  return 0;
};
/**
 * this method to get the memory items count that allowed to user to add
 * from auth reducer
 *
 * @return {number} - the memory item count
 */
export const getMemoryItemCount = (): number => {
  const {currentUser} = store.getState().auth;
  if (!currentUser?.config?.settings) {
    return 0;
  }
  const idx = (currentUser?.config?.settings || []).findIndex(
    item => item.key === 'max_memory_items_count',
  );
  if (idx > -1) {
    return +currentUser?.config?.settings[idx].value;
  }
  return 0;
};
