import React from 'react';
import useScrollTop from '../../../utils/hooks/useScrollTop';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {ProductItemType} from '@resources/interfaces/api/necklaces';
import {useLocation, useNavigate} from 'react-router-dom';
import config from '../../../config';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5%;
`;
const Title = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s5};
  letter-spacing: 2px;
  text-align: center;
  align-self: center;
  font-weight: 500;
`;
const Symbol = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s1};
  text-align: center;
  align-self: center;
`;
const Subtitle = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s6};
  letter-spacing: 1px;
  text-align: center;
  align-self: center;
`;

const Button = styled.button`
  margin-left: auto;
  margin-right: auto;
  background: ${({theme}) => theme.text.dark};
  border-radius: 10px;
  border: none;
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s7};
  cursor: pointer;
  padding: 2% 7%;
  display: inline-flex; // Use inline-flex for better alignment
  justify-content: center;
  align-items: center;
  width: fit-content; // Ensure width is based on content
  margin-top: 5%; // Adjusted for simplicity
  align-self: center;
  outline: none;
  transition:
    background-color 0.2s,
    transform 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.tertiary};
  }

  &:active {
    background-color: ${({theme}) => theme.tertiary};
    transform: scale(0.98); /* Slightly scale down the button */
  }

  &:focus {
    box-shadow: ${({theme}) => '0 0 0 2px ' + theme.tertiary + '77'};
  }

  @media (min-width: 768px) {
    margin-top: 0; // Adjust top margin for larger screens
    align-self: flex-start;
    padding: 0.5% 4%;
  }
`;
const ViewInMemoryButton = styled(Button)`
  background-color: transparent;
  color: ${({theme}) => theme.text.dark};
  border: ${({theme}) => '1px solid ' + theme.text.dark};
  padding: 2% 4%;
  margin-bottom: 2%;

  &:hover {
    background-color: ${({theme}) => theme.border + '77'};
  }

  &:active {
    background-color: ${({theme}) => theme.border + 'AA'};
    transform: scale(0.98); /* Slightly scale down the button */
  }

  &:focus {
    box-shadow: ${({theme}) => '0 0 0 2px ' + theme.border + '77'};
  }
  @media (min-width: 768px) {
    padding: 0.5% 2%;
  }
`;

const CreateMemoryResult = (): JSX.Element => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    isSuccess,
    message,
    product,
  }: {isSuccess: boolean; message: string; product: ProductItemType} =
    useLocation().state || {};

  useScrollTop();
  return (
    <Container>
      <Title>{product?.nickname || product.name || ''}</Title>
      <Subtitle>
        {t(`createMemoryResult.${isSuccess ? 'successTitle' : 'failTitle'}`)}
      </Subtitle>
      <Subtitle>
        {isSuccess ? t('createMemoryResult.successSubtitle') : message}
      </Subtitle>
      <Symbol>{isSuccess ? ':)' : ':('}</Symbol>
      <ViewInMemoryButton onClick={() => navigate(-2)}>
        {t('createMemoryResult.viewInMemory')}
      </ViewInMemoryButton>
      <Button onClick={() => (window.location.href = config.STORE_URL)}>
        {t('createMemoryResult.continueShopping')}
      </Button>
    </Container>
  );
};

export default CreateMemoryResult;
