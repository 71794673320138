import React, {useEffect, useRef, useState} from 'react';
import useScrollTop from '../../../utils/hooks/useScrollTop';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import VideoThumbnail from 'react-video-thumbnail';
import PlayIcon from '../../../resources/icons/play';
import {Memory as MemoryType} from '@resources/interfaces/api/memories/memoriesResponse';
import {getMemoryProtectedImage} from '@pages/content/memoryDetails/actions';
import MemoryDetailsSkeleton from '../../../components/memoryDetailsSkeleton';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;
const ContentContainer = styled.div`
  display: flex;
  padding: 20% 0;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  @media (min-width: 768px) {
    width: 60%;
    padding: 5%;
  }
  .image-gallery-image {
    width: 100%;
    aspect-ratio: 2;
    object-fit: contain;
    background: rgb(255, 158, 158);
    background: radial-gradient(
      circle,
      rgba(255, 158, 158, 0.2) 21%,
      rgba(238, 238, 238, 1) 100%
    );
    border-radius: 10px;
  }
  .image-gallery-thumbnail {
    border-radius: 5px;
  }
  .image-gallery-thumbnail-inner {
    border-radius: 5px;
  }
  .image-gallery-index {
    border-radius: 10px;
  }
`;
const Title = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s5};
  letter-spacing: 2px;
  text-align: center;
  align-self: center;
  font-weight: 500;
`;
const PlayIconContainer = styled.div`
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const MemoryDetails = (): JSX.Element => {
  const {memory}: {memory: MemoryType} = useLocation().state || {};
  const imageGalleryRef = useRef();
  const thumbnailRef = useRef(null);
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [thumbnailDimensions, setThumbnailDimensions] = useState({
    height: 0,
    width: 0,
  });
  const [settings, setSettings] = useState({
    showGalleryPlayButton: true,
    showNav: true,
    showVideo: false,
    useWindowKeyDown: true,
  });
  useScrollTop();
  const loadImages = async () => {
    const tempImages = await Promise.all(
      memory.media_links.map(async (item, index) => {
        const source = await getMemoryProtectedImage(
          item.url || item.thumbnail_url,
        );
        if (item.type === 'video') {
          return {
            description: index === 0 ? memory.description : '',
            thumbnail: source,
            original: source,
            renderItem: _renderVideo,
          };
        }
        return {
          description: index === 0 ? memory.description : '',
          thumbnail: source,
          original: source,
        };
      }),
    );

    setImages(tempImages);
  };

  useEffect(() => {
    loadImages();

    if (thumbnailRef.current) {
      setThumbnailDimensions({
        height: thumbnailRef.current.offsetHeight,
        width: thumbnailRef.current.offsetWidth,
      });
    }
  }, []);

  const _renderVideo = (item, index) => {
    if (settings?.showVideo) {
      return (
        <div
          style={{
            width: '100%',
            aspectRatio: 2,
          }}>
          <iframe
            height={thumbnailDimensions.height}
            width={thumbnailDimensions.width}
            src={item.original}
            frameBorder="0"
            allowFullScreen></iframe>
        </div>
      );
    }
    return (
      <div
        ref={thumbnailRef}
        style={{
          width: '100%',
          aspectRatio: 2,
          overflow: 'hidden',
        }}
        onClick={_toggleShowVideo}>
        <PlayIconContainer>
          <PlayIcon />
        </PlayIconContainer>
        <VideoThumbnail
          height={thumbnailDimensions.height}
          width={thumbnailDimensions.width}
          videoUrl={item.original}
          snapshotAtTime={5}
        />
        <div className="play-button"></div>
      </div>
    );
  };

  const _resetVideo = (index: number) => {
    setCurrentIndex(index);
    setSettings(prev => {
      return {...prev, showVideo: false, showGalleryPlayButton: true};
    });
  };

  const _toggleShowVideo = index => {
    const {showVideo} = settings;
    setSettings(prev => {
      return {...prev, showVideo: !prev.showVideo};
    });

    if (!showVideo) {
      setSettings(prev => {
        return {
          ...prev,
          showGalleryPlayButton: false,
        };
      });
    }
  };
  return (
    <Container>
      <Title>{memory?.title}</Title>
      <ContentContainer>
        {isLoading && <MemoryDetailsSkeleton />}
        <div
          style={{
            display: isLoading ? 'none' : undefined,
          }}>
          <ImageGallery
            ref={imageGalleryRef}
            items={images}
            infinite
            showBullets
            showFullscreenButton
            showNav
            onSlide={_resetVideo}
            startIndex={currentIndex}
            showPlayButton={settings.showGalleryPlayButton}
            thumbnailPosition={'bottom'}
            showIndex
            onImageLoad={() => setIsLoading(false)}
          />
        </div>
      </ContentContainer>
    </Container>
  );
};

export default MemoryDetails;
