import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import '../App.css';
import '../index.css';
import Header from '@components/header';
import Footer from '@components/footer';
import styled from 'styled-components';
import 'react-notifications/lib/notifications.css';
import AppRoutes from '@routes/appRoutes';
import {getLocalData} from '@utils/storage';
import {keys} from '../api/keys';
import {store} from '../redux/store';
import {User} from '@resources/interfaces/api/auth/login';
import {login} from '@pages/auth/login/reducer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const AppRouter = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const currentUser = getLocalData(keys.CURRENT_USER) as User;
    if (currentUser) {
      store.dispatch(login({currentUser}));
    }
  }, [navigate]);
  return (
    <Container>
      <Header />
      <AppRoutes />
      <Footer />
    </Container>
  );
};

export default AppRouter;
