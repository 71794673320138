import * as React from 'react';
import {SVGProps} from 'react';
const ChangePasscodeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={400}
    height={389}
    viewBox={'0 0 300 292'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      fill={props.fill}
      d="M129.2 7.1C84.2 12.9 42.1 43 21.3 84.3c-4.7 9.5-10.8 28.2-12.9 39.7-2.2 12.3-2.2 34.3 0 47.2 3.1 18.6 12.3 42.8 17.1 45 5.8 2.6 11.3-3 8.8-9-6.8-16.3-8.8-21.6-11-30-2.4-8.9-2.6-11.4-2.6-29.2 0-18.6.1-20 3.1-31.2 11.3-43.2 42.6-76.9 84-90.8 43.5-14.5 93.3-3.8 126.9 27.2 6.8 6.3 9.9 7.2 13.3 3.8 1.9-1.9 2-3.3 2-21.8 0-21.2-.3-22.6-5-23.7-1.8-.4-3.4 0-5.3 1.5-2.6 2.1-2.7 2.4-2.7 12.6 0 5.7-.2 10.4-.5 10.4-.2 0-4.6-2.8-9.6-6.2-17-11.3-37.6-19.3-57.9-22.3-10.1-1.6-29.4-1.8-39.8-.4z"
    />
    <path
      fill={props.fill}
      d="M137.5 44.7c-6.2 1.4-16.3 6.1-21.4 9.9-5.5 4.1-12.3 11.9-15.1 17.2-4.4 8.7-5.3 13.6-5.9 32.7l-.6 19-7.7.6c-10.6.9-17.7 5.6-21.2 14.1-2.2 5.2-2.4 78-.3 83.1 2 4.7 6.4 9.5 11.1 12l4.1 2.2h135l4.1-2.2c5.6-3 10.9-9.4 12.3-14.8 1.7-6.9 1.5-71.9-.3-77.9-3.1-10.3-11.9-16.6-23.2-16.6h-6.1l-.6-18.3c-.3-10-1.1-20-1.7-22.2-5.6-18.6-20.5-33.3-38.5-38-5.5-1.4-19.3-1.9-24-.8zm25.1 15.4c11 4.1 20.4 14.5 23.9 26.3 1.1 3.5 1.5 9.8 1.5 21.3V124l-39.7-.2-39.8-.3-.3-13c-.2-7.2.2-15.9.8-19.5 4.3-25.2 29.7-39.8 53.6-30.9zm42 77.8c8.4.1 9.3.3 11.8 2.7l2.6 2.7v72.9l-2.9 2.9-2.9 2.9h-64.1c-38.3 0-65-.4-66.5-1-5.5-2-5.7-3.8-5.4-42.4l.3-35.4 2.4-2.6c1.3-1.4 3.3-2.6 4.5-2.8 2-.3 100.5-.2 120.2.1z"
    />
    <path
      fill={props.fill}
      d="M101.5 166.6c-3.5.8-7.5 4.7-8.9 8.8-1.9 5.4-.5 10.3 3.8 14.3 3.1 2.8 4.3 3.3 8.7 3.3 4.6 0 5.5-.4 9-3.9 3.8-3.7 4.5-6 3.9-11.6-.8-7.3-8.8-12.5-16.5-10.9zM142.5 168c-5.1 2.5-7.5 6.5-7.5 12.4 0 3.8.6 5.2 3.3 8.2 2.8 3.1 4.2 3.7 8.3 4.1 7 .7 11-1.5 13.6-7.4 3.4-7.8-.2-16.1-8-18.3-4.9-1.3-5.1-1.3-9.7 1zM187.5 167.1c-7.6 2.2-11.7 11.8-8.1 18.8 5 9.6 20.1 9.3 24.3-.6 4.4-10.6-5.3-21.5-16.2-18.2z"
    />
    <path
      fill={props.fill}
      d="M263 81c-2.7 2.7-2.6 4 1.9 13.9 8 17.7 11.3 33.2 11.3 52.6.1 35.3-12.7 66.3-37.6 91-26 25.8-58.7 38.6-95.1 37.2-31.4-1.2-56.6-11.3-81.4-32.8-7.5-6.4-10.7-7.3-14.1-3.9-1.9 1.9-2 3.3-2 21.3 0 21.8.5 23.7 6.4 23.7 5.6 0 7.1-2.7 7.3-13.7.1-5.2.4-9.7.6-9.9.2-.3 4.2 2 8.8 5.1C84.7 276 101.6 283 121 287.1c14 2.9 38.1 3.1 52 .5 81.2-15.5 132.2-93.8 112.9-173.3-3.2-13.4-9.9-30.2-13.4-33.7-2.3-2.2-7.1-2-9.5.4z"
    />
  </svg>
);
export default ChangePasscodeIcon;
