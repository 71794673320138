import * as React from 'react';
import {SVGProps} from 'react';
const LinkedinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={35}
    viewBox={'0 0 34 35'}
    fill="none"
    {...props}>
    <path
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M33 17.367c0-8.804-7.138-15.942-15.942-15.942-8.805 0-15.943 7.138-15.943 15.942 0 8.805 7.138 15.943 15.943 15.943C25.861 33.31 33 26.172 33 17.367Z"
    />
    <path
      fill="#fff"
      d="M13.129 13.815h-3.3v10.656h3.3V13.815ZM11.462 12.425c1.08 0 1.952-.88 1.952-1.969a1.957 1.957 0 0 0-1.952-1.968c-1.081 0-1.952.88-1.952 1.968 0 1.09.871 1.969 1.952 1.969ZM18.407 18.884c0-1.5.687-2.387 2.01-2.387 1.215 0 1.793.854 1.793 2.387v5.596h3.284v-6.752c0-2.857-1.617-4.239-3.878-4.239-2.262 0-3.21 1.76-3.21 1.76v-1.433H15.24v10.656h3.167v-5.596.008Z"
    />
  </svg>
);
export default LinkedinIcon;
