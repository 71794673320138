export const baseUrl = 'https://qrystal-backend.canguru.com.au/public/api/v1/';
export const websiteUrl = 'https://qrystal.canguru.com.au/';
export const auth = {
  LOGIN: 'login',
  REGISTER: 'register',
  FORGET_PASSWORD: 'password/reset',
  SET_PASSWORD: 'password/set',
  LOGOUT: 'logout',
  DELETE_ACCOUNT: 'user/delete',
};
export const home = {
  ORDERS: 'orders',
  MEMORIES: 'memory/code/',
  UPDATE_NICKNAME: 'orders/qrcode/',
  MEMORY: 'memory/show/',
  ADD_MEMORY: 'memory',
  SET_PASSCODE: 'passcode/set',
  DELETE_MEDIA_MEMORY: 'memory/',
  UPDATE_PROFILE: 'user',
  SCAN_QR_CODE: 'scan',
  SCAN_STATUS: 'scan/status',
  MEMORY_GUEST: 'memory/guest/',
  UPDATE_PASSCODE: 'passcode/update',
  CONFIG: 'config',
  NOTIFY_ON_SCAN: 'scan/notify',
  NOTIFICATIONS: 'notifications',
  NOTIFICATIONS_READ_ALL: 'notifications/read/all',
  CHANGE_PASSWORD: 'users/change-password',
};
export const links = {
  THE_COLLECTION: websiteUrl + 'shop',
  HOW_IT_WORKS: websiteUrl + 'how-it-works',
  CONTACT_US: websiteUrl + 'contact-us',
  ABOUT_US: websiteUrl + 'about',
  PRIVACY: websiteUrl + 'privacy-policy',
  WARRANTY: websiteUrl + 'warranty',
  ORDERING_PAYMENT_GUIDE: websiteUrl + 'ordering-payment-guide',
  RETURN_REFUND: websiteUrl + 'refund_returns',
};
export const api = {
  ...auth,
  ...home,
};
