import React, {useRef, useState} from 'react';
import styled, {
  createGlobalStyle,
  IStyledComponent,
  useTheme,
} from 'styled-components';
import {QrStatus} from '@utils/enums';
import GeoLocationIcon from '../../resources/icons/geoLocation';
import PasscodeIcon from '../../resources/icons/passcode';
import NoPasscodeIcon from '../../resources/icons/noPasscode';
import {useTranslation} from 'react-i18next';
import SelectedIcon from '../../resources/icons/selected';
import DeleteIcon from '../../resources/icons/delete';
import BackIcon from '../../resources/icons/back';
import ReactInputVerificationCode from 'react-input-verification-code';
import {ChoosePasscodeTypeProps} from '@resources/interfaces/components/choosePasscodeType';

const CustomVerificationCodeStyles = createGlobalStyle`
    .custom-styles {
        --ReactInputVerificationCode-itemWidth: 2.5rem;
        --ReactInputVerificationCode-itemHeight: 3.5rem;
    }

    .custom-styles .ReactInputVerificationCode__item {
        position: relative;
        color:  ${({theme}) => theme.text.dark};
        font-weight: 500;
    }

    .custom-styles .ReactInputVerificationCode__item,
    .custom-styles .ReactInputVerificationCode__item.is-active {
        box-shadow: none;
    }

    .custom-styles .ReactInputVerificationCode__item:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 3px;
        background-color:  ${({theme}) => theme.border};
        transition: background-color 0.2s ease-out;
    }

    .custom-styles .ReactInputVerificationCode__item.is-active:after {
        background-color:  ${({theme}) => theme.tertiary};
    }

`;
const Container = styled.div`
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
`;
const ScrollView = styled.div`
  width: 100%;
`;
const ReactInputVerificationCodeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const CloseIconContainer = styled.div`
  position: absolute;
  top: ${({theme}) => theme.text.s12};
  left: ${({theme}) => theme.text.s12};
  background-color: ${({theme}) => theme.border + '77'};
  width: ${({theme}) => theme.text.s7};
  height: ${({theme}) => theme.text.s7};
  padding: ${({theme}) => theme.text.s12};
  border-radius: ${({theme}) => theme.text.s7};
  &:active {
    transform: scale(0.96);
    opacity: 0.8;
  }
`;

const Title = styled.div`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s8};
  text-align: center;
  padding-top: 5vh;
  padding-bottom: 2vh;
  width: 100%;
  font-weight: 500;
  letter-spacing: 1px;
`;
const Subtitle = styled.div`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s9};
  text-align: center;
  padding-top: 3vh;
  padding-bottom: 3vh;
  width: 70%;
  letter-spacing: 1px;
`;

const TypeContainer: IStyledComponent<'web', any> = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2%;
  border-top: 1px solid ${({theme}) => theme.border};
  &:active {
    opacity: 0.8;
    background-color: ${({theme}) => theme.border + '22'};
  }
`;

const LeftTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const IconContainer: IStyledComponent<'web', any> = styled.div`
  padding-right: ${({noPadding}) => (noPadding ? 0 : '5%')};
  width: ${({theme}) => theme.text.s3};
  aspect-ratio: 1;
`;

const TypeTitle = styled.div`
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme}) => theme.text.dark};
  width: 75%;
`;

const SliderContainer = styled.div`
  display: flex;
  transition: transform 0.5s ease;
`;

const LeftContainer = styled.div`
  min-width: 100%;
  transition: transform 0.5s ease;
`;

const RightContainer = styled.div`
  min-width: 100%;
  transition: transform 0.5s ease;
`;
const BackIconContainer = styled.div`
  position: absolute;
  top: ${({theme}) => theme.text.s12};
  right: -${({theme}) => theme.text.s1};
  width: ${({theme}) => theme.text.s3};
  height: ${({theme}) => theme.text.s3};
  &:active {
    transform: scale(0.96);
    opacity: 0.8;
  }
`;

const Button = styled.button`
  background: ${({theme}) => theme.text.dark};
  border-radius: 10px;
  border: none;
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s7};
  cursor: pointer;
  padding: 1% 5%;
  display: flex; // Use inline-flex for better alignment
  justify-content: center;
  align-items: center;
  width: fit-content; // Ensure width is based on content
  align-self: center;
  outline: none;
  margin: 4vh auto;
  transition:
    background-color 0.2s,
    transform 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.tertiary};
  }

  &:active {
    background-color: ${({theme}) => theme.tertiary};
    transform: scale(0.98); /* Slightly scale down the button */
  }

  &:focus {
    box-shadow: ${({theme}) => '0 0 0 2px ' + theme.tertiary + '77'};
  }

  @media (min-width: 768px) {
    align-self: flex-start;
  }
`;
const passcodeTypes = [
  {
    label: QrStatus.PASSCODE,
  },
  {
    label: QrStatus.GEO_LOCATION,
  },
  {
    label: QrStatus.NO_PASSCODE,
  },
];
/**
 * ImagePicker component allows users to select multiple images and display them as previews.
 * It supports adding new images and deleting selected ones. The component also ensures uniqueness
 * of images based on their filenames to prevent duplicates.
 *
 * @component
 * @param {ImagePickerProps} props - The properties passed to the ImagePicker component.
 * @param {Function} props.onImageSelected - Callback function that gets invoked with the updated
 * array of selected File objects whenever a new image is selected or an existing one is deleted.
 * @param {CSSProperties} [props.containerStyle] - Optional styling object for the container of the
 * ImagePicker component.
 * @returns {JSX.Element} The ImagePicker component with functionality to upload and manage images.
 */
const ChoosePasscodeType: React.FC<ChoosePasscodeTypeProps> = ({
  selectedType,
  onChoose,
  onClose,
  onSubmitPasscode,
}: ChoosePasscodeTypeProps): JSX.Element => {
  const {t} = useTranslation();
  const theme = useTheme();
  const containerRef = useRef(null);
  const [activeContainer, setActiveContainer] = useState('left');
  const [passcode, setPasscode] = useState('');

  const calculateTransform = () => {
    return activeContainer === 'left' ? '0%' : '-100%'; // Shifts the slider container to show the desired content
  };
  const onChooseHandler = (label: QrStatus) => {
    if (label === QrStatus.PASSCODE) {
      setActiveContainer('right');
      return;
    }
    onChoose && onChoose(label);
  };

  const _getLabelIcon = (label: string) => {
    switch (label) {
      case QrStatus.PASSCODE:
        return <PasscodeIcon width={'80%'} />;
      case QrStatus.NO_PASSCODE:
        return <NoPasscodeIcon width={'80%'} />;
      default:
        return <GeoLocationIcon width={'80%'} />;
    }
  };

  const _renderItem = (item, index) => {
    return (
      <TypeContainer
        onClick={() => onChooseHandler(item?.label)}
        key={index}
        withTopBorder={index === 0}>
        <LeftTypeContainer>
          <IconContainer>{_getLabelIcon(item.label)}</IconContainer>
          <TypeTitle>{t(`choosePasscodeType.${item.label}`)}</TypeTitle>
        </LeftTypeContainer>
        <IconContainer noPadding>
          {selectedType === item.label && (
            <SelectedIcon width={theme.text.s3} height={theme.text.s3} />
          )}
        </IconContainer>
      </TypeContainer>
    );
  };

  const onBackHandler = () => {
    setActiveContainer('left');
  };

  const onSubmitPasscodeHandler = () => {
    onSubmitPasscode && onSubmitPasscode(passcode);
  };

  return (
    <Container ref={containerRef}>
      <SliderContainer
        style={{transform: `translateX(${calculateTransform()})`}}>
        <LeftContainer>
          <CloseIconContainer onClick={onClose}>
            <DeleteIcon width={'100%'} height={'100%'} />
          </CloseIconContainer>
          <Title>{t(`choosePasscodeType.title`)}</Title>
          <ScrollView>{passcodeTypes.map(_renderItem)}</ScrollView>
        </LeftContainer>
        <RightContainer>
          <BackIconContainer onClick={onBackHandler}>
            <BackIcon width={'100%'} height={'100%'} />
          </BackIconContainer>
          <Title>{t(`choosePasscodeType.enterPasscodeTitle`)}</Title>
          <ReactInputVerificationCodeContainer>
            <Subtitle>{t(`choosePasscodeType.enterPasscode`)}</Subtitle>
            <div
              className="custom-styles"
              style={{
                display: activeContainer === 'right' ? 'flex' : 'none',
              }}>
              <CustomVerificationCodeStyles />
              <ReactInputVerificationCode
                autoFocus
                placeholder=""
                onChange={val => setPasscode(val)}
              />
            </div>
            <Button onClick={onSubmitPasscodeHandler}>
              {t(`choosePasscodeType.submit`)}
            </Button>
          </ReactInputVerificationCodeContainer>
        </RightContainer>
      </SliderContainer>
    </Container>
  );
};

export default ChoosePasscodeType;
