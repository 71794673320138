import {services} from '../../../api';
import {Result} from '../../../utils/enums';
import {getErrorMessage} from '../../../utils/helper';
import config from '../../../config';
import {Memory} from '../../../resources/interfaces/api/memories/memoriesResponse';
import {NotificationManager} from 'react-notifications';
import {SetPasscodeForm} from '@resources/interfaces/api/passcode';
import {getProducts} from '@pages/content/myProducts/actions';
import i18n from 'i18next';
import {
  finishGlobalLoading,
  startGlobalLoading,
} from '../../../connected-component/loading/actions';

/**
 * this function to get Memories list
 *
 * @return {Memory[] | null} Memories list or null if error happened
 */
export const getMemoriesByCode = async (
  code: string,
): Promise<Memory[] | null> => {
  try {
    const res = await services.home.getMemoriesByCode(code);
    if (res?.result === Result.ERROR || !res?.content) {
      throw new Error(getErrorMessage(res.error_description));
    }
    return res.content;
  } catch (error: any) {
    if (config.debug) {
      console.info('Get Memories FAIL: ', error);
    }
    NotificationManager.error(
      getErrorMessage(
        error?.error_description ? error?.error_description : error,
      ),
      '',
    );

    return null;
  }
};

/**
 * this function to set passcode
 *
 * @return {boolean} true if set successfully or false if error happened
 */
export const setQrPasscode = async (
  body: SetPasscodeForm,
): Promise<boolean> => {
  try {
    startGlobalLoading();
    const res = await services.home.setPasscode(body);
    if (res?.result === Result.ERROR || !res?.content) {
      throw new Error(getErrorMessage(res.error_description));
    }
    NotificationManager.success(
      i18n.t('myMemories.setPasscodeSuccessMessage'),
      '',
    );

    getProducts();
    return true;
  } catch (error: any) {
    if (config.debug) {
      console.info('set Passcode FAIL: ', error);
    }
    NotificationManager.error(
      getErrorMessage(
        error?.error_description ? error?.error_description : error,

        i18n.t('myMemories.setPasscodeFailMessage'),
      ),
      '',
    );
    return false;
  } finally {
    finishGlobalLoading();
  }
};
