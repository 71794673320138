import React from 'react';
import styled, {keyframes} from 'styled-components';

// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Create the animation component
const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: ${({theme}) => theme.tertiary};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${rotate} 1s infinite linear;
`;

// Placeholder component
const Loader = (): React.ReactElement => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}>
    <Spinner />
  </div>
);

export default Loader;
