import {services} from '../../../api';
import {Result} from '@utils/enums';
import {getErrorMessage} from '@utils/helper';
import config from '../../../config';
import {store} from '../../../redux/store';
import {Buffer} from 'buffer';

export const getMemoryProtectedImage = async (imagesUri: string) => {
  try {
    const {currentUser} = store.getState().auth || {};
    const res = await services.home.getProtectedImageMemory(imagesUri, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${currentUser.token}`,
      },
    });
    if (res?.result === Result.ERROR) {
      throw new Error(getErrorMessage(res.error_description));
    }
    const data = `data:${
      res.headers['content-type']
    };base64,${new Buffer(res.data, 'binary').toString('base64')}`;
    return data;
  } catch (error: any) {
    if (config.debug) {
      console.info('get Protected image FAIL: ', error);
    }
    return '';
  }
};
