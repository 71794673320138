import React from 'react';
import useScrollTop from '../../../utils/hooks/useScrollTop';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import NotFoundIcon from '../../../resources/icons/notFound';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;
const NotFoundText = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s4};
  font-weight: bold;
  letter-spacing: 1px;
`;
const Button = styled.button`
  opacity: ${({disabled}) => (disabled ? 0.7 : 1)};
  background: ${({theme}) => theme.text.dark};
  border-radius: 10px;
  border: none;
  margin: 5% auto;
  display: flex;
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s7};
  padding: 3% 20%;
  justify-content: center;
  align-items: center;
  width: fit-content; // Ensure width is based on content
  align-self: center;
  outline: none;
  ${({disabled}) =>
    disabled
      ? ''
      : `    
        cursor: pointer;
    transition: background-color 0.2s,
    transform 0.2s;
    `};

  &:hover {
    background-color: ${({theme, disabled}) =>
      disabled ? undefined : theme.tertiary};
  }

  &:active {
    background-color: ${({theme, disabled}) =>
      disabled ? undefined : theme.tertiary};
    transform: ${({disabled}) => (disabled ? undefined : 'scale(0.98)')};
  }

  &:focus {
    box-shadow: ${({theme, disabled}) =>
      disabled ? undefined : '0 0 0 2px ' + theme.tertiary + '77'};
  }

  @media (min-width: 768px) {
    padding: 1% 5%;
    margin-top: 2%;
    margin-bottom: 5%;
  }
`;
const NotFound = () => {
  const navigate = useNavigate();

  useScrollTop();

  const goBack = () => {
    navigate(-1); // Goes back to the previous page
  };
  return (
    <Container>
      <NotFoundIcon width={'100%'} />
      <NotFoundText>Page Not Found</NotFoundText>
      <Button onClick={goBack}>Go Back</Button>
    </Container>
  );
};

export default NotFound;
