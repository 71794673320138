const Path = {
  Auth: {
    login: '/login',
  },
  MyProducts: {
    Root: '/my-products',
  },
  Memories: {
    Root: '/memories',
    createMemory: '/memories/create-memory',
    memoryDetails: '/memories/memory-details',
    createMemoryResult: '/memories/create-memory/result',
  },
};
export default Path;
