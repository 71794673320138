import {createSlice} from '@reduxjs/toolkit';
import {LoadingState} from '@resources/interfaces/connected-component/loading';

const initialState: LoadingState = {
  isVisible: false,
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: state => {
      state.isVisible = true;
    },
    finishLoading: state => {
      state.isVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {startLoading, finishLoading} = loadingSlice.actions;

export default loadingSlice.reducer;
