import * as React from 'react';
import {SVGProps} from 'react';
const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox={'0 0 24 24'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <g fill={props.fill || '#141414'}>
      <path d="M17.071 4.929a1 1 0 0 1-1.414 1.414 8 8 0 1 0 0 11.314 1 1 0 0 1 1.414 1.414c-3.905 3.905-10.237 3.905-14.142 0s-3.905-10.237 0-14.142 10.237-3.905 14.142 0z" />
      <path d="M10 13a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2z" />
      <path d="M19.293 9.707a1 1 0 1 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414l-3 3a1 1 0 0 1-1.414-1.414L21.586 12z" />
    </g>
  </svg>
);
export default LogoutIcon;
