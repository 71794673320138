import React, {ChangeEvent, useEffect, useState} from 'react';
import styled from 'styled-components';
import AddIcon from '../../resources/icons/add';
import DeleteIcon from '../../resources/icons/delete';
import {ImagePickerProps} from '@resources/interfaces/components/imagePicker';

const ImagePickerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; // Align items at the start of the container
  gap: 2%; // Adds a gap between images
  border: ${({theme}) => '1px solid ' + theme.border};
  border-radius: 10px;
  padding: 2%;
  width: 80%;
  aspect-ratio: 2;
  overflow: auto;
  @media (min-width: 768px) {
    width: 40%;
  }
  z-index: 99;
`;

const ImageSlot = styled.div`
  flex: 0 1 47%;
  aspect-ratio: 1;
  border: ${({theme}) => '1px dashed ' + theme.border};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  @media (min-width: 768px) {
    flex: 0 1 22%;
  }
`;
const AddButton = styled(AddIcon)`
  width: 30%;
`;

const ImageInput = styled.input`
  display: none;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
`;

const DeleteIconContainer = styled.button`
  position: absolute;
  width: 20%;
  aspect-ratio: 1;
  top: 5%;
  right: 5%;
  border: none;
  cursor: pointer;
  background-color: ${({theme}) => theme.text.light};
  opacity: 0.75;
  border-radius: 50%;
  padding: 2%;
  box-shadow: ${({theme}) => '0px 2px 4px ' + theme.text.dark + '77'};
  transition:
    background-color 0.2s,
    transform 0.2s;

  &:active {
    transform: scale(0.95); /* Slightly scale down the button */
    opacity: 1;
  }
`;

const DeleteButton = styled(DeleteIcon)`
  width: 100%;
`;

/**
 * ImagePicker component allows users to select multiple images and display them as previews.
 * It supports adding new images and deleting selected ones. The component also ensures uniqueness
 * of images based on their filenames to prevent duplicates.
 *
 * @component
 * @param {ImagePickerProps} props - The properties passed to the ImagePicker component.
 * @param {Function} props.onImageSelected - Callback function that gets invoked with the updated
 * array of selected File objects whenever a new image is selected or an existing one is deleted.
 * @param {CSSProperties} [props.containerStyle] - Optional styling object for the container of the
 * ImagePicker component.
 * @returns {JSX.Element} The ImagePicker component with functionality to upload and manage images.
 */
const ImagePicker: React.FC<ImagePickerProps> = ({
  onImageSelected,
  containerStyle,
}: ImagePickerProps): JSX.Element => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<
    Array<{name: string; url: string}>
  >([]);

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files);
    const uniqueFiles = Array.from(
      new Map(
        [...selectedFiles, ...files].map(item => [item.name, item]),
      ).values(),
    );
    setSelectedFiles(uniqueFiles);
    const newImageUrls = files.map((file: File) => {
      return {
        name: file.name,
        url: URL.createObjectURL(file),
      };
    });
    const uniqueImages = Array.from(
      new Map(
        [...previewImages, ...newImageUrls].map(item => [item.name, item]),
      ).values(),
    );
    setPreviewImages(uniqueImages);
    event.target.value = '';
  };

  const handleImageDelete = image => {
    setSelectedFiles(prev => prev.filter(file => file.name !== image.name));
    setPreviewImages(prev => prev.filter(img => img.name !== image.name));
  };

  useEffect(() => {
    if (onImageSelected) {
      onImageSelected(selectedFiles);
    }
  }, [selectedFiles]);

  return (
    <ImagePickerContainer style={containerStyle}>
      <ImageSlot as="label">
        <ImageInput
          type={'file'}
          accept={'image/png, image/gif, image/jpeg'}
          multiple
          onChange={handleImageUpload}
        />
        <AddButton />
      </ImageSlot>
      {previewImages.map((image, index) => (
        <ImageSlot key={index}>
          <DeleteIconContainer onClick={() => handleImageDelete(image)}>
            <DeleteButton />
          </DeleteIconContainer>
          <Image src={image.url} alt={`selected-${index}`} />
        </ImageSlot>
      ))}
    </ImagePickerContainer>
  );
};

ImagePicker.defaultProps = {
  containerStyle: {},
};

export default ImagePicker;
