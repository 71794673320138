import React, {useEffect, useState} from 'react';
import styled, {IStyledComponent, useTheme} from 'styled-components';
import appleIcon from '../../resources/images/appleIcon.png';
import googlePlayIcon from '../../resources/images/googlePlayIcon.png';
import logo from '../../resources/images/logo.png';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import MenuIcon from '../../resources/icons/menu';
import config from '../../config';
import {links} from '../../api/api';
import {Theme} from '@resources/interfaces/colors';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import Path from '@routes/path';
import {connect} from 'react-redux';
import {RootState} from '../../redux/store';
import {AuthState} from '@resources/interfaces/redux/auth';
import LogoutIcon from '../../resources/icons/logout';
import {endSession} from '@pages/auth/login/actions';

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1% 4%;
  background-color: ${({theme}) => theme.text.dark + '99'};
  position: sticky;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(3px);

  @media (max-width: 768px) {
    justify-content: space-between;
  }
`;

const DrawerContainer = styled.div`
  z-index: 1001;
`;

const Logo = styled.img`
  height: 60px;
`;

const MenuButton = styled(MenuIcon)`
  width: ${({theme}) => theme.text.s3};
  height: ${({theme}) => theme.text.s3};
  display: none;
  margin-left: auto;
  @media (max-width: 768px) {
    display: block;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;

  @media (max-width: 768px) {
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 10%;
  }
`;
const TabContainer: IStyledComponent<'web', any> = styled.div`
  transition: border-top 0.3s ease-in-out; /* Smoothly animates the border */
  border-top: ${({isActive, theme}) =>
    isActive ? '3px solid ' + theme.text.dark : '3px solid transparent'};

  cursor: pointer;
  &:hover {
    border-top: ${({theme}) => '3px solid ' + theme.text.dark};
  }
  @media (max-width: 768px) {
    border-bottom: ${({theme}) => '1px solid ' + theme.border};
    width: 94%;
    align-self: center;
  }
`;

const Tab = styled.a`
  display: block;
  padding: 1.05em;
  font-weight: 500;
  background: none;
  color: ${({theme}) => theme.text.light};
  font-size: 1.05em;
  letter-spacing: -0.02em;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  font-family: 'Raleway', sans-serif;
`;
const LinkItem = styled(Link)`
  display: block;
  padding: 1em;
  font-weight: 500;
  background: none;
  color: ${({theme}) => theme.text.light};
  font-size: 1.05em;
  letter-spacing: -0.02em;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  font-family: 'Raleway', sans-serif;
`;
const TabSeparator = styled.span`
  color: ${({theme}) => theme.text.light};
  text-align: center;
`;

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 5%;
  transition:
    background-color 0.2s,
    transform 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.tertiary + '22'};
  }

  &:active {
    background-color: ${({theme}) => theme.tertiary + '22'};
    transform: scale(0.98); /* Slightly scale down the button */
  }

  &:focus {
    box-shadow: ${({theme}) => '0 0 0 2px ' + theme.tertiary + '22'};
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const Icon = styled.img`
  width: ${({theme}) => theme.text.s4};
  cursor: pointer;
  margin-left: ${({theme}) => theme.text.s4};
`;

const Header = ({auth}: {auth: AuthState}) => {
  const theme = useTheme() as Theme;
  const {currentUser} = auth || {};
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {isMobile} = useWindowDimensions();
  useEffect(() => {
    const link = document.createElement('link');
    link.href =
      'https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);
  const logoutHandler = async () => {
    navigate(Path.Auth.login);
    await endSession(false, false);
  };
  const closeMenu = () => setIsMenuOpen(false);
  return (
    <React.Fragment>
      <DrawerContainer>
        <Drawer
          style={{
            backgroundColor: theme.text.dark,
          }}
          open={isMenuOpen}
          onClose={closeMenu}
          direction="right">
          <TabsContainer>
            <Logo src={logo} alt="Logo" />
            {currentUser?.token && (
              <TabContainer>
                <LinkItem onClick={closeMenu} to={Path.MyProducts.Root}>
                  MY PRODUCTS
                </LinkItem>
              </TabContainer>
            )}
            <TabContainer>
              <Tab onClick={closeMenu} href={links.THE_COLLECTION}>
                THE COLLECTION
              </Tab>
            </TabContainer>
            <TabContainer>
              <Tab onClick={closeMenu} href={links.HOW_IT_WORKS}>
                HOW IT WORKS
              </Tab>
            </TabContainer>
            <TabContainer>
              <Tab onClick={closeMenu} href={links.CONTACT_US}>
                CONTACT US
              </Tab>
            </TabContainer>
            <TabContainer>
              <Tab onClick={closeMenu} href={links.ABOUT_US}>
                ABOUT US
              </Tab>
            </TabContainer>
            {currentUser?.token && (
              <TabContainer>
                <LinkItem
                  onClick={() => {
                    closeMenu();
                    logoutHandler();
                  }}
                  to={Path.MyProducts.Root}>
                  LOGOUT
                </LinkItem>
              </TabContainer>
            )}
          </TabsContainer>
        </Drawer>
      </DrawerContainer>
      <HeaderContainer>
        <Logo src={logo} alt="Logo" />
        <MenuButton onClick={() => setIsMenuOpen(prev => !prev)}>
          Menu
        </MenuButton>
        {!isMobile && (
          <TabsContainer>
            {currentUser?.token && (
              <>
                <TabContainer
                  isActive={location.pathname === Path.MyProducts.Root}>
                  <LinkItem to={Path.MyProducts.Root}>MY PRODUCTS</LinkItem>
                </TabContainer>
                <TabSeparator>|</TabSeparator>
              </>
            )}
            <TabContainer>
              <Tab href={links.THE_COLLECTION}>THE COLLECTION</Tab>
            </TabContainer>
            <TabSeparator>|</TabSeparator>
            <TabContainer>
              <Tab href={links.HOW_IT_WORKS}>HOW IT WORKS</Tab>
            </TabContainer>
            <TabSeparator>|</TabSeparator>
            <TabContainer>
              <Tab href={links.CONTACT_US}>CONTACT US</Tab>
            </TabContainer>
            <TabSeparator>|</TabSeparator>
            <TabContainer>
              <Tab href={links.ABOUT_US}>ABOUT US</Tab>
            </TabContainer>
          </TabsContainer>
        )}
        <IconsContainer>
          <Icon
            src={appleIcon}
            alt={'apple store'}
            onClick={() => {
              window.open(
                config.APPLE_STORE_URL,
                '_blank',
                'noopener,noreferrer',
              );
            }}
          />
          <Icon
            src={googlePlayIcon}
            alt={'google play'}
            onClick={() => {
              window.open(
                config.GOOGLE_PLAY_URL,
                '_blank',
                'noopener,noreferrer',
              );
            }}
          />
          {currentUser?.token && (
            <>
              <TabSeparator style={{minWidth: theme.text.s3}}>|</TabSeparator>
              <LogoutContainer onClick={logoutHandler} title="Logout">
                <LogoutIcon
                  width={theme.text.s3}
                  height={theme.text.s3}
                  fill={theme.text.light}
                />
              </LogoutContainer>
            </>
          )}
        </IconsContainer>
      </HeaderContainer>
    </React.Fragment>
  );
};
const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, null)(Header);
