import * as React from 'react';
import {SVGProps} from 'react';
const SaveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={27}
    viewBox={'0 0 27 27'}
    fill="none"
    {...props}>
    <circle cx={13.5} cy={13.5} r={13} stroke="#D9D9D9" />
    <path
      stroke="#0AFFA0"
      strokeLinecap="round"
      strokeWidth={2}
      d="m8 12.5 4.5 4.5 8-8"
    />
  </svg>
);
export default SaveIcon;
