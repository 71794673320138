import * as React from 'react';
import {SVGProps} from 'react';
const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={34}
    height={35}
    viewBox={'0 0 34 35'}
    fill="none"
    {...props}>
    <path
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M32.885 17.32c0-8.804-7.138-15.942-15.943-15.942C8.138 1.378 1 8.516 1 17.321c0 8.804 7.138 15.942 15.942 15.942 8.805 0 15.943-7.138 15.943-15.942Z"
    />
    <path
      fill="#fff"
      d="M14.514 26.955h3.878v-9.718h2.706l.285-3.25h-2.999v-1.852c0-.77.15-1.072.896-1.072h2.103V7.687h-2.689c-2.89 0-4.189 1.273-4.189 3.702v2.597h-2.019v3.293h2.02v9.676h.008Z"
    />
  </svg>
);
export default FacebookIcon;
