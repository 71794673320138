import {store} from '../../redux/store';
import {startLoading, finishLoading} from './reducer';

export const startGlobalLoading = () => {
  store.dispatch(startLoading());
};

/**
 * This function close global Loading
 */
export const finishGlobalLoading = () => {
  store.dispatch(finishLoading());
};
