import {Config} from '@resources/interfaces/config';

const config: Config = {
  debug: true,
  APP_NAME: 'QRYSTAL',
  KEY_NAME: 'QRYSTAL_',
  DEFAULT_PLACEHOLDER: './resources/assets/placeholderImage.jpg',
  APPLE_STORE_URL: 'http://qrystal.canguru.com.au/shop',
  GOOGLE_PLAY_URL: 'http://qrystal.canguru.com.au/shop',
  STORE_URL: 'http://qrystal.canguru.com.au/shop',
};

export default config;
