import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonWrapper = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  margin: 1% auto;
  width: 100%;
`;
const BottomSkeletonWrapper = styled.div`
  position: relative;
  display: flex;
  margin: 1%;
  width: 16%;
  aspect-ratio: 0.75;
`;
const VirtualSlider = styled.div`
  width: 100%;
  aspect-ratio: 2;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const VirtualThumbnail = styled.div`
  width: 100%;
  height: 100%;
`;

const MemoryDetailsSkeleton = () => {
  return (
    <>
      <SkeletonWrapper>
        <Skeleton
          height={'100%'}
          width={'100%'}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            borderRadius: 10,
          }}
        />
        <VirtualSlider />
      </SkeletonWrapper>
      <BottomContainer>
        {[0, 1, 2].map(i => {
          return (
            <BottomSkeletonWrapper key={i}>
              <Skeleton
                height={'100%'}
                width={'100%'}
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  borderRadius: 10,
                }}
              />
              <VirtualThumbnail />
            </BottomSkeletonWrapper>
          );
        })}
      </BottomContainer>
    </>
  );
};

export default MemoryDetailsSkeleton;
