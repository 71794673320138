import React from 'react';
import './App.css';
import './index.css';
import {Provider} from 'react-redux';
import {ThemeProvider} from 'styled-components';
import lightTheme from './resources/theme/lightTheme';
import {store} from './redux/store';
import GlobalLoading from './connected-component/loading';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from 'react-notifications';
import AppRouter from './routes';
import {BrowserRouter as Router} from 'react-router-dom';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={lightTheme}>
        <Router>
          <AppRouter />
        </Router>
        <GlobalLoading />
      </ThemeProvider>
      <NotificationContainer />
    </Provider>
  );
}

export default App;
