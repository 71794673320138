import {services} from '../../../api';
import {Result} from '../../../utils/enums';
import {getErrorMessage} from '../../../utils/helper';
import config from '../../../config';
import {store} from '../../../redux/store';
import {productsFail, productsLoading, setProducts} from './reducer';
import {ProductItemType} from '@resources/interfaces/api/necklaces';
import {NotificationManager} from 'react-notifications';
import {
  finishGlobalLoading,
  startGlobalLoading,
} from '../../../connected-component/loading/actions';
import i18n from 'i18next';
import {ConfigType} from '@resources/interfaces/api/configApi';
import {startSession} from '@pages/auth/login/actions';

/**
 * this function to get products list
 *
 * @return {ProductItemType[] | null} products list or null if error happened
 */
export const getProducts = async (): Promise<ProductItemType[] | null> => {
  try {
    store.dispatch(productsLoading());
    const res = await services.home.getOrders();
    if (res?.result === Result.ERROR || !res?.content) {
      throw new Error(getErrorMessage(res.error_description));
    }
    store.dispatch(setProducts({data: res.content}));
    return res.content;
  } catch (error: any) {
    if (config.debug) {
      console.info('Get Orders FAIL: ', error);
    }
    store.dispatch(productsFail());
    NotificationManager.error(
      getErrorMessage(
        error?.error_description ? error?.error_description : error,
      ),
      '',
    );
    return null;
  }
};

/**
 * this function to change nickname
 *
 * @return {boolean} true if change successfully or false if error happened
 */
export const changeNickname = async (
  id: string,
  nickname: string,
): Promise<boolean> => {
  try {
    startGlobalLoading();
    const res = await services.home.changeNickname(id, nickname);
    if (res?.result === Result.ERROR || !res?.content) {
      throw new Error(getErrorMessage(res.error_description));
    }
    NotificationManager.success(i18n.t('changeNickname.saveSuccessfully'), '');
    await getProducts();
    return true;
  } catch (error: any) {
    if (config.debug) {
      console.info('change nickname FAIL: ', error);
    }
    NotificationManager.error(getErrorMessage(i18n.t('app.error')), '');
    return false;
  } finally {
    finishGlobalLoading();
  }
};

/**
 * this function to get Config list
 *
 * @return Promise<ConfigType | null>
 */
export const getConfig = async (): Promise<ConfigType | null> => {
  try {
    const res = await services.home.getConfig();
    if (res?.result === Result.ERROR || !res?.content) {
      throw new Error(getErrorMessage(res.error_description));
    }
    const {currentUser} = store.getState().auth || {};
    const tempUser = {...currentUser};
    tempUser.config = res.content;
    startSession({
      ...tempUser,
    });
    return res?.content;
  } catch (error: any) {
    if (config.debug) {
      console.info('Get Config FAIL: ', error);
    }
    return null;
  }
};
