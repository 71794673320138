import React, {useState} from 'react';
import useScrollTop from '../../../utils/hooks/useScrollTop';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import ImagePicker from '@components/imagePicker';
import CreateMemoryFields from '@components/createMemoryFields';
import MemoryPreview from '@components/memoriesReveiw';
import 'reactjs-popup/dist/index.css';
import {ProductItemType} from '@resources/interfaces/api/necklaces';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  finishGlobalLoading,
  startGlobalLoading,
} from '../../../connected-component/loading/actions';
import moment from 'moment';
import {addMemory} from '@pages/content/createMemory/actions';
import Path from '@routes/path';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5%;
`;
const Title = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s5};
  letter-spacing: 2px;
  text-align: center;
  align-self: center;
  font-weight: 500;
  padding-bottom: 5%;
`;
const Subtitle = styled.p`
  color: ${({theme}) => theme.text.dark};
  font-size: ${({theme}) => theme.text.s8};
  letter-spacing: 1px;
  text-align: center;
  align-self: center;
  padding-bottom: 1%;
`;

const Space = styled.div`
  height: 5vh;
`;
const Button = styled.button`
  opacity: ${({disabled}) => (disabled ? 0.7 : 1)};
  margin-left: auto;
  margin-right: auto;
  background: ${({theme}) => theme.text.dark};
  border-radius: 10px;
  border: none;
  color: ${({theme}) => theme.text.light};
  font-size: ${({theme}) => theme.text.s7};
  padding: 0.5% 4%;
  display: inline-flex; // Use inline-flex for better alignment
  justify-content: center;
  align-items: center;
  width: fit-content; // Ensure width is based on content
  margin-top: 5%; // Adjusted for simplicity
  align-self: center;
  outline: none;
  ${({disabled}) =>
    disabled
      ? ''
      : `    
        cursor: pointer;
    transition: background-color 0.2s,
    transform 0.2s;
    `};
  &:hover {
    background-color: ${({theme, disabled}) =>
      disabled ? undefined : theme.tertiary};
  }
  &:active {
    background-color: ${({theme, disabled}) =>
      disabled ? undefined : theme.tertiary};
    transform: ${({disabled}) => (disabled ? undefined : 'scale(0.98)')};
  }

  &:focus {
    box-shadow: ${({theme, disabled}) =>
      disabled ? undefined : '0 0 0 2px ' + theme.tertiary + '77'};
  }

  @media (min-width: 768px) {
    margin-top: 0; // Adjust top margin for larger screens
    align-self: flex-start;
  }
`;

const CreateMemory = (): JSX.Element => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {product}: {product: ProductItemType} = useLocation().state || {};
  const [images, setImages] = useState<File[]>([]);
  const [form, setForm] = useState<{date: Date | string; name: string}>();
  useScrollTop();

  const onImageSelected = (images: File[]) => {
    setImages(images);
  };

  const onSubmitHandler = async () => {
    startGlobalLoading();
    const date = moment(form?.date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const formData = new FormData();

    formData.append('title', form?.name);
    formData.append('date', date);
    formData.append('description', '');
    formData.append('qrcode_id', product?.qrcode_id);
    let thumbnailSet = false;
    images.forEach(file => {
      formData.append('media[]', file);

      // Check if a thumbnail has been set, if not, check if the current file can be a thumbnail
      if (
        !thumbnailSet &&
        (file.type === 'image/png' || file.type === 'image/jpeg')
      ) {
        formData.append('thumbnail', file);
        thumbnailSet = true; // Ensure only the first suitable file is set as the thumbnail
      }
    });
    const res = await addMemory(formData);
    finishGlobalLoading();
    navigate(Path.Memories.createMemoryResult, {
      state: {
        isSuccess: res.status,
        message: res.message,
        product,
      },
    });
  };

  return (
    <Container>
      <Title>{t('createMemory.title')}</Title>
      <CreateMemoryFields
        image={product?.image || ''}
        onFormChange={val => setForm(val)}
      />
      <Space />
      <ImagePicker onImageSelected={onImageSelected} />
      <Space />
      <Subtitle>{t('createMemory.preview')}</Subtitle>
      <MemoryPreview images={images.map(img => URL.createObjectURL(img))} />
      <Space />
      <Button
        disabled={!form?.date || !form?.name || (images || [])?.length === 0}
        onClick={onSubmitHandler}>
        {t('createMemory.save')}
      </Button>
    </Container>
  );
};

export default CreateMemory;
