import {Navigate, useRoutes} from 'react-router-dom';
import Path from './path';
import NotFound from 'pages/content/notFound';
import MyProducts from 'pages/content/myProducts';
import MyMemories from 'pages/content/myMemories';
import CreateMemory from 'pages/content/createMemory';
import CreateMemoryResult from 'pages/content/createMemoryResult';
import MemoryDetails from '@pages/content/memoryDetails';
import Login from '@pages/auth/login';
import {store} from '../redux/store';

const ProtectedRoute = ({children}: {children: React.ReactElement}) => {
  const {currentUser} = store.getState().auth || {};

  if (!currentUser?.token) {
    return <Navigate to={Path.Auth.login} replace />;
  }

  return children;
};
const AuthRoute = ({children}: {children: React.ReactElement}) => {
  const {currentUser} = store.getState().auth || {};

  if (currentUser?.token) {
    return <Navigate to={Path.MyProducts.Root} replace />;
  }

  return children;
};
const AppRoutes = () => {
  return useRoutes([
    {
      path: Path.Auth.login,
      element: (
        <AuthRoute>
          <Login />
        </AuthRoute>
      ),
    },
    {
      path: Path.MyProducts.Root,
      element: <MyProducts />,
    },
    {
      path: Path.Memories.Root,
      element: (
        <ProtectedRoute>
          <MyMemories />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.Memories.memoryDetails,
      element: (
        <ProtectedRoute>
          <MemoryDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.Memories.createMemory,
      element: (
        <ProtectedRoute>
          <CreateMemory />
        </ProtectedRoute>
      ),
    },
    {
      path: Path.Memories.createMemoryResult,
      element: (
        <ProtectedRoute>
          <CreateMemoryResult />
        </ProtectedRoute>
      ),
    },
    {
      path: '/',
      element: (
        <AuthRoute>
          <Login />
        </AuthRoute>
      ),
    },
    {
      path: '*',
      element: <NotFound />,
    }, // Catch-all route for unmatched paths
  ]);
};

export default AppRoutes;
