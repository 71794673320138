import {store} from '../../../redux/store';
import {login, logout} from './reducer';
import {services} from '../../../api';
import {LoginForm, User} from '../../../resources/interfaces/api/auth/login';
import {Result} from '../../../utils/enums';
import config from '../../../config';
import {keys} from '../../../api/keys';
import {getErrorMessage} from '../../../utils/helper';
import {RegisterForm} from '../../../resources/interfaces/api/auth/register';
import {NotificationManager} from 'react-notifications';
import {
  finishGlobalLoading,
  startGlobalLoading,
} from '../../../connected-component/loading/actions';
import i18n from 'i18next';
import {flushLocalData, setLocalData} from '@utils/storage';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
/**
 * this function to log in
 *
 * @param {LoginForm} form - the login form body
 */
export const logIn = async (form: LoginForm): Promise<boolean> => {
  try {
    startGlobalLoading();
    const res = await services.authentication.login(form, headers);
    if (res?.result === Result.ERROR || !res?.content) {
      throw new Error(
        getErrorMessage(
          res.error_description,
          i18n.t('login.loginFailMessage'),
        ),
      );
    }
    const savedRes = await startSession({
      ...(res?.content || {}),
    });
    if (!savedRes) {
      throw new Error(
        getErrorMessage(
          res.error_description,
          i18n.t('login.loginFailMessage'),
        ),
      );
    }
    NotificationManager.success(
      i18n.t('login.loginSuccessMessage') + (res?.content?.name || ''),
      '',
    );
    return true;
  } catch (error: any) {
    if (config.debug) {
      console.info('LOGIN FAIL: ', error);
    }
    NotificationManager.error(
      getErrorMessage(
        error?.error_description,
        i18n.t('login.loginFailMessage'),
      ),
      '',
    );
    return false;
  } finally {
    finishGlobalLoading();
  }
};
export const startSession = async (user: User) => {
  try {
    const currentUser = {...user};
    setLocalData(keys.CURRENT_USER, currentUser);
    store.dispatch(
      login({
        currentUser,
      }),
    );
    return true;
  } catch (error) {
    if (config.debug) {
      console.info('LOGIN FAIL: ', error);
    }
    return false;
  }
};
export const endSession = async (tokenExpire = false, reload = true) => {
  try {
    startGlobalLoading();
    store.dispatch(logout());
    const res = flushLocalData();
    if (res && reload) window.location.reload();
    if (!tokenExpire) {
      NotificationManager.success(i18n.t('login.logoutSuccessfully'));
    }
    return true;
  } catch (error) {
    if (config.debug) {
      console.info('LOGOUT FAIL: ', error);
    }
    return false;
  } finally {
    finishGlobalLoading();
  }
};
export const register = async (form: RegisterForm) => {
  try {
    startGlobalLoading();
    const res = await services.authentication.register(form, headers);
    if (res?.result === Result.ERROR || !res?.content) {
      throw new Error(getErrorMessage(res.error_description));
    }
    const savedRes = await startSession({
      ...(res?.content || {}),
    });
    if (!savedRes) {
      throw new Error(getErrorMessage(res.error_description));
    }
    NotificationManager.success(
      i18n.t('register.registerSuccessMessage') + (res?.content?.name || ''),
    );
    return true;
  } catch (error: any) {
    if (config.debug) {
      console.info('REGISTER FAIL: ', error?.error_description);
    }
    NotificationManager.error(getErrorMessage(error?.error_description), '');
    return false;
  } finally {
    finishGlobalLoading();
  }
};
