import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {MemoryProps} from '@resources/interfaces/components/memory';

const MemoryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0;
  border-top: ${({theme}) => '1px solid ' + theme.border};
  border-bottom: ${({theme}) => '1px solid ' + theme.border};
  transition:
    background-color 0.2s,
    transform 0.2s;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.7;
  }

  &:focus {
    box-shadow: ${({theme}) => '0 0 0 2px ' + theme.tertiary + '22'};
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme}) => theme.text.dark};
  margin: 0;
  padding-bottom: ${({theme}) => theme.text.s10};
`;

const DateText = styled.p`
  font-weight: 400;
  font-size: ${({theme}) => theme.text.s9};
  color: ${({theme}) => theme.text.dark};
  margin: 0;
`;
/**
 * Represents a memory item with a title and date, styled and displayed within a container.
 *
 * @component
 *
 * @param {MemoryProps} props - The properties passed to the Memory component.
 * @param {string} props.title - The title of the memory.
 * @param {Date|string} props.date - The date of the memory, accepted as a Date object or a string.
 * @param {React.CSSProperties} [props.containerStyle={}] - Optional custom styles for the memory item container.
 *
 * @returns {JSX.Element} A styled memory item containing a title and a formatted date.
 */
const Memory: React.FC<MemoryProps> = ({
  title,
  date,
  containerStyle,
  onClick,
}) => {
  const formatDate = moment(date).format('DD MMM YYYY');
  return (
    <MemoryContainer onClick={onClick} style={containerStyle}>
      <Title>{title}</Title>
      <DateText>{formatDate}</DateText>
    </MemoryContainer>
  );
};

Memory.defaultProps = {
  containerStyle: {},
  date: new Date(),
};

export default Memory;
