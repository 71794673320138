import * as React from 'react';
import {SVGProps} from 'react';
const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={57}
    height={57}
    viewBox={'0 0 57 57'}
    fill="none"
    {...props}>
    <path
      stroke="#111"
      d="M56.5 28.5c0 15.464-12.536 28-28 28s-28-12.536-28-28 12.536-28 28-28 28 12.536 28 28Z"
    />
    <path
      stroke="#111"
      strokeLinecap="round"
      strokeWidth={2}
      d="M34 16 18 28.5 34 41"
    />
  </svg>
);
export default ArrowLeftIcon;
