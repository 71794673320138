import * as React from 'react';
import {SVGProps} from 'react';
const LogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={50}
    height={35}
    viewBox={'0 0 50 35'}
    fill="none"
    {...props}>
    <path
      fill="#fff"
      d="M17.455 34.521a18.081 18.081 0 0 0 1.325-.053 18.058 18.058 0 0 0 .698-.068c.062-.009.125-.014.187-.022.21-.027.42-.058.628-.094l.184-.033a17.154 17.154 0 0 0 .694-.14c.14-.03.278-.064.416-.098.075-.02.15-.037.222-.057.201-.052.4-.108.599-.168.05-.015.1-.032.149-.048.155-.049.31-.1.463-.153.032-.01.064-.023.097-.035l-.749-2.155a14.762 14.762 0 0 1-4.913.835c-8.205 0-14.879-6.718-14.879-14.974S9.25 2.286 17.455 2.286c8.205 0 14.879 6.718 14.879 14.975 0 4.683-2.168 9.067-5.842 11.895l-7.25-6.536-1.516 1.703 10.99 9.906 3.418.014-3.925-3.538c.345-.28.677-.572.996-.874l.192-.185.122-.119c.064-.063.125-.127.188-.191l.12-.123c.06-.063.118-.126.176-.19l.125-.134c.055-.06.108-.12.161-.181l.136-.155.14-.166.15-.181.116-.145a12.783 12.783 0 0 0 .45-.589c.02-.025.038-.052.056-.077.074-.103.147-.205.218-.31a17.219 17.219 0 0 0 1.157-1.942h.002c.355-.694.666-1.417.927-2.164l-.004.004c.462-1.324.765-2.712.895-4.134.004-.045.01-.09.012-.136a74.582 74.582 0 0 0 .03-.435c.005-.059.007-.118.01-.175.004-.094.008-.188.01-.283l.005-.131a17.878 17.878 0 0 0-.017-1.313C34.121 7.278 26.634.019 17.488 0h-.035C7.997 0 .304 7.744.304 17.26c0 9.518 7.695 17.261 17.151 17.261Z"
    />
    <path
      fill="#fff"
      d="M28.273 2.708h8.134c2.947 0 5.72 1.155 7.803 3.253a11.07 11.07 0 0 1 3.232 7.853 11.07 11.07 0 0 1-3.232 7.853 10.926 10.926 0 0 1-6.742 3.203l-1.517.133-1.619-1.46c-.278.752-.603 1.48-.973 2.18l9.743 8.784 3.405.013-8.254-7.44c6.476-.905 11.462-6.498 11.462-13.266 0-7.398-5.958-13.395-13.31-13.395h-12.11a18.021 18.021 0 0 1 3.977 2.288Z"
    />
  </svg>
);
export default LogoIcon;
