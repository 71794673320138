import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import Product from '@components/product'; // Assuming Skeleton is from a package
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonWrapper = styled.div`
  position: relative;
  align-self: center;
  margin: 1% auto;
  width: 90%;
  @media (min-width: 768px) {
    width: 52%;
  }
`;

const MyProductsSkeleton = () => {
  return [0, 1, 2].map(i => (
    <SkeletonWrapper key={i}>
      <Skeleton
        height={'100%'}
        width={'100%'}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          borderRadius: 10,
        }}
      />
      <Product
        containerStyle={{visibility: 'hidden'}}
        src={''}
        description={''}
        title={''}
      />
    </SkeletonWrapper>
  ));
};

export default MyProductsSkeleton;
