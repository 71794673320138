import {AxiosResponse} from 'axios';
import axiosInstance from './axiosInstance';
import {finishGlobalLoading} from '../connected-component/loading/actions';

const handleError = (err: string) => {
  finishGlobalLoading();
  // NotificationManager.error(i18n.t('app.error'), '');
};

const http = {
  get: (url: string, config = {}) =>
    new Promise((resolve, reject) => {
      axiosInstance
        .get(url, config)
        .then((res: AxiosResponse<any>) => {
          resolve(res.data);
        })
        .catch(err => {
          handleError(err);
          reject(err);
        });
    }),
  post: (url: string, body: any, headers = {}) =>
    new Promise((resolve, reject) => {
      axiosInstance
        .post(url, body, {headers})
        .then(res => resolve(res.data))
        .catch(err => {
          handleError(err);
          reject(err);
        });
    }),
  patch: (url: string, body: any, headers = {}) =>
    new Promise((resolve, reject) => {
      axiosInstance
        .patch(url, body, {headers})
        .then(res => resolve(res.data))
        .catch(err => {
          handleError(err);
          reject(err);
        });
    }),
  delete: (url: string) =>
    new Promise((resolve, reject) => {
      axiosInstance
        .delete(url)
        .then(res => resolve(res.data))
        .catch(err => {
          handleError(err);
          reject(err);
        });
    }),
};

export default http;
