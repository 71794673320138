import {services} from '../../../api';
import {Result} from '@utils/enums';
import {getErrorMessage} from '@utils/helper';
import config from '../../../config';
import {finishGlobalLoading} from '../../../connected-component/loading/actions';

/**
 * this function to add Memory
 *
 * @return {boolean} true if success and false if fail
 */
export const addMemory = async (
  body: any,
): Promise<{
  status: boolean;
  message?: string;
}> => {
  try {
    const res = await services.home.addMemory(body);
    if (res?.result === Result.ERROR || !res?.content) {
      throw new Error(getErrorMessage(res?.error_description));
    }
    return {status: true};
  } catch (error: any) {
    if (config.debug) {
      console.info('ADD Memory FAIL: ', error);
    }
    return {status: false, message: getErrorMessage(error)};
  } finally {
    finishGlobalLoading();
  }
};
