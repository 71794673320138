import * as React from 'react';
import {SVGProps} from 'react';
const PlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={54} viewBox={'0 0 48 54'} {...props} fill="none">
    <path
      fill={props.fill || '#fff'}
      d="M41.5 18.67c3.333 1.924 3.333 6.736 0 8.66l-30 17.32C8.167 46.576 4 44.17 4 40.32V5.68c0-3.85 4.167-6.255 7.5-4.33l30 17.32Z"
    />
    <defs />
  </svg>
);
export default PlayIcon;
