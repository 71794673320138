import axios from 'axios';
import {api, baseUrl} from './api';
import http from './http';
import {LoginForm, LoginResponse} from '@resources/interfaces/api/auth/login';
import {LogoutResponse} from '@resources/interfaces/api/auth/logout';
import {getProductsResponse} from '@resources/interfaces/api/necklaces';
import {MemoriesResponse} from '@resources/interfaces/api/memories/memoriesResponse';
import {MemoryResponse} from '@resources/interfaces/api/memories/memory';
import {
  SetPasscodeForm,
  SetPasscodeResponse,
  UpdatePasscodeForm,
  UpdatePasscodeResponse,
} from '@resources/interfaces/api/passcode';
import {BaseApiResponse} from '@resources/interfaces/api/baseApiResponse';
import {ProfileUpdateResponse} from '@resources/interfaces/api/profile';
import {
  NotifyOnScanForm,
  NotifyOnScanResponse,
  ScanForm,
  ScanMemoryForm,
  ScanMemoryResponse,
  ScanResponse,
  ScanStatusBody,
  ScanStatusResponse,
} from '@resources/interfaces/api/scan';
import {
  RegisterForm,
  RegisterResponse,
} from '@resources/interfaces/api/auth/register';
import {
  ForgotPasswordForm,
  ForgotPasswordResponse,
} from '@resources/interfaces/api/auth/forgotPassword';
import {
  SetPasswordForm,
  SetPasswordResponse,
} from '@resources/interfaces/api/auth/setPassword';
import {ConfigResponse} from '@resources/interfaces/api/configApi';
import {
  NotificationsAsReadResponse,
  NotificationsResponse,
} from '@resources/interfaces/api/notifications';
import {
  ChangePasswordForm,
  ChangePasswordResponse,
} from '@resources/interfaces/api/changePassword';
import {
  DeleteAccountBody,
  DeleteAccountResponse,
} from '@resources/interfaces/api/auth/deleteAccount';

const authentication = {
  login: (body: LoginForm, headers: any): Promise<LoginResponse> =>
    new Promise((resolve, reject) => {
      const url = baseUrl + api.LOGIN;
      axios
        .post(url, body, {
          headers,
        })
        .then(res => {
          resolve(res.data as LoginResponse);
        })
        .catch(err => {
          reject(err?.response?.data as LoginResponse);
        });
    }),
  register: (body: RegisterForm, headers: any): Promise<RegisterResponse> =>
    new Promise((resolve, reject) => {
      const url = baseUrl + api.REGISTER;
      axios
        .post(url, body, {
          headers,
        })
        .then(res => {
          resolve(res.data as RegisterResponse);
        })
        .catch(err => {
          reject(err?.response?.data as RegisterResponse);
        });
    }),
  forgotPassword: (
    body: ForgotPasswordForm,
    headers: any,
  ): Promise<ForgotPasswordResponse> =>
    new Promise((resolve, reject) => {
      const url = baseUrl + api.FORGET_PASSWORD;
      axios
        .post(url, body, {
          headers,
        })
        .then(res => {
          resolve(res.data as ForgotPasswordResponse);
        })
        .catch(err => {
          reject(err?.response?.data as ForgotPasswordResponse);
        });
    }),
  setPassword: (
    body: SetPasswordForm,
    headers: any,
  ): Promise<SetPasswordResponse> =>
    new Promise((resolve, reject) => {
      const url = baseUrl + api.SET_PASSWORD;
      axios
        .post(url, body, {
          headers,
        })
        .then(res => {
          resolve(res.data as SetPasswordResponse);
        })
        .catch(err => {
          reject(err?.response?.data as SetPasswordResponse);
        });
    }),
  deleteAccount: (body: DeleteAccountBody): Promise<DeleteAccountResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.DELETE_ACCOUNT, body)
        .then(res => resolve(res as DeleteAccountResponse))
        .catch(e => reject(e));
    }),
  logout: (): Promise<LogoutResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(api.LOGOUT)
        .then(res => resolve(res as LogoutResponse))
        .catch(e => reject(e));
    }),
};

const home = {
  getOrders: (): Promise<getProductsResponse> =>
    new Promise((resolve, reject) => {
      // const url = `${api.ORDERS}?page=${page}`;
      http
        .get(api.ORDERS)
        .then(res => resolve(res as getProductsResponse))
        .catch(e => reject(e));
    }),
  getMemoriesByCode: (code: string): Promise<MemoriesResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(api.MEMORIES + code)
        .then(res => resolve(res as MemoriesResponse))
        .catch(e => reject(e));
    }),
  getMemory: (id: number, body: ScanMemoryForm): Promise<MemoryResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.MEMORY + id, body)
        .then(res => resolve(res as MemoryResponse))
        .catch(e => reject(e));
    }),
  getProtectedImageMemory: (imageUrl: string, config: any): Promise<any> =>
    new Promise((resolve, reject) => {
      axios
        .get(imageUrl, config)
        .then(res => resolve(res))
        .catch(e => reject(e));
    }),
  addMemory: (body: any): Promise<MemoryResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.ADD_MEMORY, body, {'Content-Type': 'multipart/form-data; '})
        .then(res => resolve(res as MemoryResponse))
        .catch(e => reject(e));
    }),
  updateMemory: (id: number, body: any): Promise<MemoryResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.MEMORY + id, body, {'Content-Type': 'multipart/form-data; '})
        .then(res => resolve(res as MemoryResponse))
        .catch(e => reject(e));
    }),
  setPasscode: (body: SetPasscodeForm): Promise<SetPasscodeResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.SET_PASSCODE, body)
        .then(res => resolve(res as SetPasscodeResponse))
        .catch(e => reject(e));
    }),
  changeNickname: (
    id: string,
    nickname: string,
  ): Promise<SetPasscodeResponse> =>
    new Promise((resolve, reject) => {
      const url = api.UPDATE_NICKNAME + id;
      http
        .post(url, {nickname})
        .then(res => resolve(res as SetPasscodeResponse))
        .catch(e => reject(e));
    }),
  deleteMediaInMemory: (
    memoryId: number,
    mediaId: string,
  ): Promise<BaseApiResponse> =>
    new Promise((resolve, reject) => {
      const url = api.DELETE_MEDIA_MEMORY + `${memoryId}/media/${mediaId}`;
      http
        .delete(url)
        .then(res => resolve(res as BaseApiResponse))
        .catch(e => reject(e));
    }),
  deleteMemory: (memoryId: number): Promise<BaseApiResponse> =>
    new Promise((resolve, reject) => {
      const url = api.MEMORY + memoryId;
      http
        .delete(url)
        .then(res => resolve(res as BaseApiResponse))
        .catch(e => reject(e));
    }),
  updateProfile: (body: FormData): Promise<ProfileUpdateResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.UPDATE_PROFILE, body)
        .then(res => resolve(res as ProfileUpdateResponse))
        .catch(e => reject(e));
    }),
  updatePasscode: (body: UpdatePasscodeForm): Promise<UpdatePasscodeResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.UPDATE_PASSCODE, body)
        .then(res => resolve(res as UpdatePasscodeResponse))
        .catch(e => reject(e));
    }),
  getConfig: (): Promise<ConfigResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(api.CONFIG)
        .then(res => resolve(res as ConfigResponse))
        .catch(e => reject(e));
    }),
  getNotifications: (page: number): Promise<NotificationsResponse> =>
    new Promise((resolve, reject) => {
      const url = api.NOTIFICATIONS + `?page=${page}`;
      http
        .get(url)
        .then(res => resolve(res as NotificationsResponse))
        .catch(e => reject(e));
    }),
  markNotificationsAsRead: (): Promise<NotificationsAsReadResponse> =>
    new Promise((resolve, reject) => {
      http
        .patch(api.NOTIFICATIONS_READ_ALL, {})
        .then(res => resolve(res as NotificationsAsReadResponse))
        .catch(e => reject(e));
    }),
  changePassword: (body: ChangePasswordForm): Promise<ChangePasswordResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.CHANGE_PASSWORD, body)
        .then(res => resolve(res as ChangePasswordResponse))
        .catch(e => reject(e));
    }),
};
const scan = {
  scanQrCode: (body: ScanForm): Promise<ScanResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.SCAN_QR_CODE, body)
        .then(res => resolve(res as ScanResponse))
        .catch(e => reject(e));
    }),
  getMemory: (id: number, body: ScanMemoryForm): Promise<ScanMemoryResponse> =>
    new Promise((resolve, reject) => {
      const url = api.MEMORY_GUEST + id;
      http
        .post(url, body)
        .then(res => resolve(res as ScanMemoryResponse))
        .catch(e => reject(e));
    }),
  notifyOnScan: (body: NotifyOnScanForm): Promise<NotifyOnScanResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.NOTIFY_ON_SCAN, body)
        .then(res => resolve(res as NotifyOnScanResponse))
        .catch(e => reject(e));
    }),
  getStatus: (body: ScanStatusBody): Promise<ScanStatusResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.SCAN_STATUS, body)
        .then(res => resolve(res as ScanStatusResponse))
        .catch(e => reject(e));
    }),
};

export const services = {authentication, home, scan};
