import * as React from 'react';
import {SVGProps} from 'react';
const GooglePlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={33}
    viewBox={'0 0 33 33'}
    fill="none"
    {...props}>
    <rect width={32} height={32} x={0.5} y={0.5} stroke="#fff" rx={4.5} />
    <path
      stroke="#fff"
      d="m16.89 16.278 3.64 3.912-8.995 5.064-.003.002a1.81 1.81 0 0 1-2.545-.794l7.902-8.184ZM17.437 15.772l3.97-3.444 3.822 2.164.01-.016-.007.025c.316.163.571.416.736.726l-8.53.545Zm0 0 4.009 3.91 3.77-2.129.009-.004.008-.004a1.706 1.706 0 0 0 .735-2.318l-8.53.545ZM8.834 8.735l7.627 7.05-7.627 7.443V8.735ZM11.56 6.751l.005.002 8.955 5.074-3.562 3.476-7.966-7.778A1.802 1.802 0 0 1 10.628 6.5h.001c.327 0 .648.087.932.251Z"
    />
  </svg>
);
export default GooglePlayIcon;
