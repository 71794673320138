import React, {SVGProps} from 'react';

const SelectedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={29}
    height={28}
    viewBox={'0 0 29 28'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      fill={props.fill || '#FF9E9E'}
      d="M11.327.209C1.532 2.284-3.11 13.213 2.274 21.54c5.844 9.045 19.29 8.311 24.217-1.317 1.675-3.284 2.076-9.587.6-9.587-.567 0-.759.434-.584 1.334 2.143 11.113-11.57 19.04-20.465 11.838C-5.32 14.614 5.625-3.284 19.13 2.418c1.109.475 1.425.542 1.625.383 1.768-1.475-5.401-3.45-9.428-2.592Z"
    />
    <path
      fill={props.fill || '#FF9E9E'}
      d="M21.156 7.901c-6.311 6.794-6.911 7.403-7.12 7.128-.125-.175-1.133-1.567-2.234-3.101-2.075-2.885-2.434-3.193-2.976-2.543-.292.35-.283.359 2.501 4.218 2.526 3.502 2.693 3.677 3.185 3.327C15.195 16.446 29 1.407 29 1.149c0-.359-.317-.684-.675-.684-.15 0-3.376 3.343-7.17 7.436Z"
    />
  </svg>
);
export default SelectedIcon;
