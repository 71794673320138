import * as React from 'react';
import {SVGProps} from 'react';
const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    viewBox={'0 0 35 35'}
    fill="none"
    {...props}>
    <path
      stroke="#fff"
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d="M17.638 33.263c-8.805 0-15.943-7.138-15.943-15.942 0-8.805 7.138-15.943 15.943-15.943 8.804 0 15.942 7.138 15.942 15.943 0 8.804-7.138 15.942-15.942 15.942Z"
    />
    <path
      fill="#fff"
      d="M17.637 10.158c2.338 0 2.614.008 3.536.05.854.042 1.315.184 1.625.302.41.159.695.352 1.005.653.31.31.494.595.654 1.005.117.31.26.771.301 1.626.042.921.05 1.198.05 3.535s-.008 2.614-.05 3.535c-.042.855-.184 1.316-.301 1.625-.16.411-.352.696-.654 1.006a2.714 2.714 0 0 1-1.005.653c-.31.118-.77.26-1.625.302-.922.042-1.198.05-3.535.05-2.338 0-2.614-.008-3.536-.05-.854-.042-1.315-.184-1.625-.302a2.784 2.784 0 0 1-1.005-.653 2.676 2.676 0 0 1-.654-1.006c-.117-.31-.26-.77-.301-1.625-.042-.921-.05-1.198-.05-3.535s.008-2.614.05-3.535c.042-.855.184-1.316.301-1.626.16-.41.352-.695.654-1.005.31-.31.595-.494 1.005-.653.31-.117.77-.26 1.625-.302.922-.042 1.198-.05 3.535-.05Zm0-1.575c-2.37 0-2.672.008-3.602.05-.93.042-1.566.193-2.12.41a4.291 4.291 0 0 0-1.55 1.006 4.183 4.183 0 0 0-1.004 1.55c-.218.553-.36 1.19-.403 2.12-.041.93-.05 1.23-.05 3.602 0 2.37.009 2.672.05 3.602.042.93.193 1.567.403 2.12.226.578.519 1.063 1.005 1.55.486.485.972.787 1.55 1.005.553.217 1.19.36 2.12.41.93.042 1.23.05 3.601.05s2.673-.008 3.603-.05c.93-.042 1.566-.193 2.12-.41a4.292 4.292 0 0 0 1.55-1.006 4.184 4.184 0 0 0 1.004-1.55c.218-.553.36-1.19.41-2.119.043-.93.051-1.231.051-3.602 0-2.371-.008-2.673-.05-3.603-.042-.93-.193-1.566-.41-2.12a4.29 4.29 0 0 0-1.006-1.549 4.184 4.184 0 0 0-1.55-1.005c-.553-.218-1.19-.36-2.12-.41-.93-.043-1.23-.051-3.602-.051Z"
    />
    <path
      fill="#fff"
      d="M17.637 12.83a4.49 4.49 0 1 0 0 8.981 4.49 4.49 0 0 0 0-8.98Zm0 7.406a2.917 2.917 0 0 1-2.916-2.916 2.917 2.917 0 0 1 2.916-2.915 2.917 2.917 0 0 1 2.915 2.915 2.917 2.917 0 0 1-2.915 2.916ZM23.35 12.654a1.048 1.048 0 1 1-2.095 0 1.048 1.048 0 0 1 2.095 0Z"
    />
  </svg>
);
export default InstagramIcon;
