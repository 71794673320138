import React, {SVGProps} from 'react';
const NoPasscodeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={45}
    height={44}
    viewBox={'0 0 45 44'}
    {...props}
    fill={'none'}
    stroke={'none'}>
    <path
      fill={props.fill || '#000'}
      d="M9.186.07c-1.54.244-3.292.919-4.573 1.77C3.062 2.865 1.461 4.82.811 6.472.09 8.291.09 8.301.04 12.808L0 16.885l.3.293.3.294h2.172c2.191 0 2.451-.04 2.722-.382.09-.117.12-1.046.13-3.588 0-2.023.04-3.666.11-4.008.37-2.004 2.181-3.656 4.343-3.97 2.351-.332 4.673 1.096 5.463 3.354.2.587.21.821.23 3.676l.01 3.06h-.3c-1.981 0-3.602 1.261-3.942 3.08-.15.801-.15 21.39 0 22.192.29 1.535 1.39 2.669 2.892 2.982.41.078 4.102.098 14.16.078l13.608-.03.57-.263a4.08 4.08 0 0 0 1.912-1.828l.32-.646V18.4l-.27-.557a3.91 3.91 0 0 0-2.772-2.131c-.34-.059-4.193-.098-10.527-.098h-9.997v-2.816c0-4.047-.17-5.064-1.15-7.01C18.892 3.043 16.4 1.068 13.349.305 12.259.03 10.217-.086 9.186.07Zm3.743 2.004c.79.205 2.111.792 2.771 1.232 1.942 1.3 3.363 3.49 3.733 5.758.05.352.1 1.965.1 3.588v2.962H17.632v-3.06c0-2.708-.02-3.158-.19-3.803-.52-1.975-1.862-3.559-3.733-4.429-1.17-.537-1.831-.665-3.282-.616-1.08.04-1.361.088-2.061.343-2.272.83-3.853 2.561-4.413 4.81-.12.498-.16 1.31-.18 3.627-.02 1.642-.03 3.01-.04 3.05-.01.049-.41.078-.911.078h-.9v-3.07c0-3.324.06-3.94.55-5.191 1.02-2.669 3.482-4.732 6.404-5.367.91-.196 3.122-.147 4.053.088Zm29.2 15.603c.26.127.54.371.7.606l.27.4.03 11.029c.02 10.04.01 11.057-.14 11.35-.23.44-.44.645-.86.86-.361.176-.822.186-13.85.186h-13.49l-.44-.205a1.976 1.976 0 0 1-.7-.606l-.27-.401-.03-10.852c-.02-7.547 0-10.97.08-11.223.14-.49.56-.949 1.05-1.164.38-.176 1.021-.185 13.8-.185h13.409l.44.205Z"
    />
    <path
      fill={props.fill || '#000'}
      d="M27.568 24.424c-1.1.205-2.121.968-2.671 1.994-.29.548-.31.655-.31 1.613 0 1.164.16 1.604.85 2.366l.38.42-.3 1.74c-.17.959-.27 1.848-.24 1.985.04.127.21.323.39.43.29.176.51.196 2.702.196h2.392l.29-.283c.36-.362.36-.43.02-2.464l-.27-1.633.32-.352c.74-.821 1.1-1.965.92-2.894a3.884 3.884 0 0 0-3.152-3.118c-.66-.118-.72-.118-1.32 0Zm1.251 1.896c.75.313 1.211.997 1.221 1.809 0 .577-.27 1.056-.78 1.398-.25.166-.45.381-.49.538-.03.146.03.86.15 1.583.12.724.22 1.399.22 1.487 0 .156-.11.175-.951.175-.52 0-.95-.02-.95-.048 0-.02.11-.714.25-1.535.14-.822.23-1.565.2-1.652-.03-.098-.26-.333-.51-.538-.641-.509-.841-.86-.841-1.486 0-.645.22-1.124.66-1.447.58-.44 1.2-.528 1.821-.284Z"
    />
  </svg>
);
export default NoPasscodeIcon;
