import * as React from 'react';
import {SVGProps} from 'react';
const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={11}
    viewBox={'0 0 12 11'}
    fill="none"
    {...props}>
    <path
      stroke={props.fill || '#959595'}
      d="m1.188.98 9.593 9.593M10.348.98.754 10.573"
    />
  </svg>
);
export default DeleteIcon;
