import * as React from 'react';
import {SVGProps} from 'react';
const AppleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={33}
    height={33}
    viewBox={'0 0 33 33'}
    fill="none"
    {...props}>
    <rect width={32} height={32} x={0.5} y={0.5} stroke="#fff" rx={4.5} />
    <path
      fill="#fff"
      d="M21.529 16.104c-.02-2.4 1.907-3.568 1.995-3.622-1.092-1.64-2.785-1.866-3.38-1.883-1.421-.155-2.8.876-3.523.876-.724 0-1.853-.861-3.056-.836-1.546.025-2.993.947-3.787 2.38-1.638 2.922-.417 7.215 1.152 9.577.785 1.157 1.703 2.448 2.903 2.403 1.175-.05 1.613-.771 3.03-.771 1.417 0 1.816.77 3.04.742 1.26-.02 2.055-1.162 2.812-2.329.906-1.325 1.27-2.631 1.285-2.698-.03-.01-2.447-.962-2.471-3.839Zm-2.313-7.06c.631-.813 1.064-1.921.944-3.044-.913.041-2.056.652-2.715 1.448-.583.702-1.103 1.852-.97 2.933 1.028.08 2.082-.534 2.741-1.335v-.001Z"
    />
  </svg>
);
export default AppleIcon;
